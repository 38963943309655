









































































































































































































































































































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { State, Action, Getter } from 'vuex-class';

import { endpoints } from '../../endpoints';
import buildUrl from 'build-url';

import { DatasetState } from '@/store/datasets/types';

import SCEMap from './Map.vue';


@Component({
    components: {
        SCEMap
    }
})
export default class SupplyChainExplorer extends Vue {

    @State('datasets') datasets!: DatasetState;
            
    show_splash: boolean = true;

    // Display Settings.
    commodity: string = "Apples";
    metric: string = "tonnes";
    spatial_type: string = "lga";
    border_crossings: number | null = null;
    spatial_selection: any = null;
    supply_chain_leg: string = "production_imports";


    get dataset(): any {
        return this.datasets.dataset;
    }


    //-----------------------------
    //--- Visualization Options ---
    //-----------------------------


    // The list of commodity groups which can be selected for tracing through the supply chain.
    commodity_list = [
        "Apples",
        "Bananas",
        "Grapes",
        "Kiwifruit",
        "Mandarins",
        "Melons",
        "Oranges",
        "Tomatoes",
        "Liquor"
    ]


    // The available metric items that can be reported upon.
    // Note: these are temporary for the moment.
    // They need to be:
    // 1) % Total Tonnes (from for first 3 maps, to for last map)
    // 2) Number of unique enterprises (destinations for first 3 maps, origins for last map)
    // 3) Number of unique LGAs (destinations for first 3 maps, origins for last map)
    metric_items = {
        'trip_transport_costs': {
            text: 'Freight task costs',
            value: 'trip_transport_costs',
            valueformat: "$,.3s",
            valuesuffix: "",
            units: '($)',
            tickprefixformat: "$",
            customDisabled: false
        },
        'tonnes': {
            text: 'Annual tonnes',
            value: 'tonnes',
            valueformat: ",.4s",
            valuesuffix: " t",
            units: '(tonne)',
            tickprefixformat: "",
            customDisabled: false
        },
    }


    // The spatial regions which can be visualized on the map.
    spatialTypeItems = {
        'lga': {
            text: 'Local Government Areas',
            value: 'lga',
            geojson: './docs/lga.json'
        },
        'state': {
            text: 'States and Territories',
            value: 'state',
            geojson: './docs/states.json'
        },
    }


    // Options loaded via endpoints.
    lga_items: string[] = [];
    state_items: string[] = [];


    // The available selection items for selecting the number of border crossings.
    border_items_list = [
        { value: 0, text: "No Crossings (i.e. Intrastate)" },
        { value: 1, text: "1 Crossing (i.e. Neighbouring State/Territory)" },
        { value: 2, text: "2 Crossings" },
        { value: 3, text: "3 Crossings" },
    ]


    mounted() {

        // Load the list of LGAs.
        Vue.axios({
            url: buildUrl(endpoints.boundariesUrl(this.dataset), { queryParams: { layer: 'LGA' } })
        }).then((response: any) => {
            this.lga_items = response.data;
        }, (error: any) => {
            console.log(error);
        });

        // Load the list of States & Territories.
        Vue.axios({
            url: buildUrl(endpoints.boundariesUrl(this.dataset), { queryParams: { layer: 'STE11a' } })
        }).then((response: any) => {
            this.state_items = response.data;
        }, (error: any) => {
            console.log(error);
        });
    }


    clearSpatialSelection(e: any) {
        this.spatial_selection = null;
    }  


    //-----------------------------
    //-------      Data       -----
    //-----------------------------


    // Configuration for the 4 supply chain leg maps.
    densitymaps = {
        production_imports: {
            map_id: "production_imports",
            title: "Production and Imports",
            direction: "orig",
        },
        processing: {
            map_id: "processing",
            title: "Processing",
            direction: "orig"
        },
        distribution: {
            map_id: "distribution",
            title: "Distribution",
            direction: "orig"
        },
        consumption_exports: {
            map_id: "consumption_exports",
            title: "Consumption and Exports",
            direction: "dest"
        }
    }


}

