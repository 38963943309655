





























import { Component, Prop, Vue } from 'vue-property-decorator';
import { ScenarioAction, ActionType } from './types'

@Component({
    components: {
    },
})
export default class ActionParameter extends Vue {

    @Prop() private action!: ScenarioAction;
    @Prop() private parameter!: any;
    @Prop() private parameter_key!: any;
    @Prop() private placeholder_value!: string;

    ActionType: any = ActionType;


    // Getter/setter for changing the parameter value.
    get parameter_value(): string {
        return !this.action.parameters ? null : this.action.parameters[this.parameter_key];
    }
    set parameter_value(value: string) {

        // Set/delete the parameter.
        if (value != null) {

            // Initialize the 'parameters' key if it does not exist... A parameter will need to be set soon!
            if (!this.action.parameters) {
                Vue.set(this.action, 'parameters', {});
            }

            // Set the new parameter.
            Vue.set(this.action.parameters, this.parameter_key, value);

        } else if (this.action.parameters) {

            // Remove the key for the parameter.
            Vue.delete(this.action.parameters, this.parameter_key);

            // Remove the 'parameters' key if there are no parameters.
            if (Object.keys(this.action.parameters).length === 0) {
                Vue.delete(this.action, 'parameters');
            }
        }
    }

    // Event handler for then the parameter value changes.
    onValueChanged(parameterKey: string, value: any) {
        this.$emit('parameter_changed', this.action);
    }
}

