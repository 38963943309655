



















import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class LegendItem extends Vue {
    @Prop() private color!: string;
    @Prop() private icon_style!: string;
    @Prop() private outlined!: string;
    @Prop() private item_value!: string;
    @Prop() private isVisible!: string;

    toggleVisibility() {
        this.$emit("toggle-visibility", this.item_value);
    }
}

