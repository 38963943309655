export enum StrategyType {
    CLOSED_ROADS = "Closed Roads",
    FEATURE_ID = "Feature ID",
    ROAD = "Road",
    RAIL_LINE = "Rail Line",
    SHIPPING_CHANNEL = "Shipping Channel",
    ENTERPRISE_TYPE = "Enterprise Type",
    ENTERPRISE_COMMODITY = "Enterprise Commodity",
    GEOJSON = "GeoJSON",
    AREA = "Area",
}

export enum ActionType {
    CLOSE = "Close",
    ALTER = "Alter",
    BUILD = "Build",
}

export enum Baseline {
    SHORT_TERM_FORECAST = "short-term",
    ANNUAL = "annual"
}

export enum PolygonOperator {
    UNION = "union",
    INTERSECTION = "intersection"
}

export enum FeatureType {
    ROAD = "Road",
    RAIL = "Rail",
    SEA = "Sea",
    AIR = "Air",
    ENTERPRISE = "Enterprise",
}

export type ParameterConfig = {
    label: string;
    hint: string;
    type: string;
    //rules: any;
    select_classes?: Array<{ text: string; value: any }>;
    width: number;
    worksWith: FeatureType[];
};

export type ScenarioAction = {
    action_type: ActionType,
    strategy_type: StrategyType,
    feature_type: FeatureType,
    value: any,
    description: string,
    parameters?: any
}

export type Scenario = {
    actions: ScenarioAction[],
    baseline: string,
    date: Date | undefined,
    polygon_operator: string,
}