


































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { State, Action, Getter } from 'vuex-class';

import { bus } from '@/pages/transitweb/main'
import { bordersStyle, selectedBordersStyle, highlightedStyle } from '@/components/layers/boundaries'

import { FiltersState, FilterState } from '@/store/filters/types';
import { DatasetState, DensityType } from '@/store/datasets/types';

var mapboxgl = require('mapbox-gl');

import buildUrl from 'build-url';
import { isNullOrUndefined } from 'util';


@Component({
    components: {
    }
})
export default class TransitFilter extends Vue {

    // Multiselect bindings
    options: any = [];

    private isSpatialSelecting: boolean = false;
    private isLoading: boolean = true;

    // Properties for configuring
    @Prop() private filterTitle!: string;
    @Prop() private item_list_url!: any;
    @Prop() private item_list_params!: any;
    @Prop() private tile_query_param!: string;

    // If spatial filter props have been set
    @Prop() private tileSource!: string;
    @Prop() private tileLayer!: string;

    @Prop() private isSelectable!: boolean;

    @Prop() private getMap!: any;

    @Prop() private densityType!: DensityType;

    @State('filters') filters!: FiltersState;
    @Action('filters/setFilterValue') setFilterValue: any;
    @Action('filters/clearFilters') clearFilters: any;
    @Action('filters/setQueryParams') setFiltersQueryParams: any;
    @Getter('filters/isSpatialFilter') isSpatialFilterStore: any; // Returns an anonymous function

    @State('datasets') datasets!: DatasetState;

    @Getter('auth/isConstrained') isConstrained: any; // Returns an anonymous function
    @Getter('auth/constrainedValues') constrainedValues: any; // Returns an anonymous function

    //@Watch('item_list_params', { deep: true })
    //onParamsChanged(val: any, oldVal: any) {
    //    var current = this.value

    //       this.loadList().then(() => {
    //             //If value is set to null, don't reset the filter values, however any other value will reset them.
    //            if (val != null) {
    //                var list_items = this.options.map((value: any) => { return value.code }).filter(this.onlyUnique) // Seems to have duplicate list items in commodities
    //                var intersection: number[] = current.filter((x: number) => list_items.includes(x));
    //                this.setFilterValue({ filter: this.tile_query_param, value: intersection })
    //                bus.$emit('filter_list_updated', val)
    //            }

    //       });
    //}


    @Watch('item_list_params', { deep: true })
    onParamsChanged(val: any, oldVal: any) {

        var current = this.value

        var loadPromise = this.loadList().then(() => {
            var list_items = this.options.map((value: any) => { return value.code }).filter(this.onlyUnique) // Seems to have duplicate list items in commodities
            var intersection: number[] = current.filter((x: number) => list_items.includes(x));
            this.setFilterValue({ filter: this.tile_query_param, value: intersection })

        });

        // Wait for the current event loop to finish, then check on the last promise
        this.$nextTick(() => {

            // The last filter to be updated
            if (this.tile_query_param === 'destenterprisecategory') {

                // Wait for the last promise to settle
                Promise.all([loadPromise.catch((error: any) => error)]).then(() => {

                    bus.$emit('filter_lists_updated'); // Refresh map
                    //console.log('All lists updated!');

                });
            }
        });

    }


    onlyUnique(value: number, index: number, array: number[]) {
        return array.indexOf(value) === index;
    }


    get value() {
        return this.filters.filter_types[this.tile_query_param].value
    }
    set value(val) {

        //if (this.isSpatialFilter) {

        //    // Display a polygon on the map representing the selection
        //    this.ensureBordersLoaded();
        //    this.map.setFilter(this.tileLayerBordersId, this.selectedCodeFilter);

        //}

        this.$emit('input', this.tile_query_param, val);
    }

    get isFiltered(): boolean {
        return (!isNullOrUndefined(this.value) && this.value.length > 0)
    }
    get dataset(): any {
        return this.datasets.dataset;
    }
    get isSpatialFilter(): boolean {
        return this.isSpatialFilterStore(this.tile_query_param)
    }

    get isConstrainedToOneValue(): boolean {
        return (this.options.length === 1 && this.options[0]['disabled'] === true)
    }


    remove(item: any) {
        // Note: Vue doesn't react to modifying arrays, so need to reassign.
        // https://v2.vuejs.org/v2/guide/reactivity.html#For-Arrays
        var newArray = this.value.slice(0); // Copy all elements starting from 0th index.
        newArray.splice(this.value.indexOf(item.code), 1)
        this.value = newArray;              // Reassign.
    }

    created() {

        // If its a spatial filter check all the required props have been given.
        if (this.tileSource && !this.tileLayer) {
            throw ('When providing a tileSource a tileLayer is also required')
        }

        // Set the options for a 'special' filter, so the chip is displayed on say a refresh.
        if (!this.isSelectable && this.isFiltered) {
            this.options = [{ code: this.value[0], name: this.value[0] }];
        }

        // 'Special' filters like critical link / inbound / outbound don't have lists to load.
        if (!isNullOrUndefined(this.item_list_url)) {
            this.loadList()
        } else {
            this.isLoading = false;
        }

    }


    loadList() {

        this.isLoading = true;

        // Load the list
        var vm = this;

        var queryParams = {};
        if (this.isSpatialFilter) {
            queryParams = { layer: this.tileLayer } // Spatial filter needs the layer specified.
        } else {
            queryParams = Object.assign({}, {}, this.item_list_params)
        }

        return Vue.axios({
            url: buildUrl(this.item_list_url, { queryParams: queryParams })
        }).then((response: any) => {

            if (this.isConstrained(this.tile_query_param)) {

                var options = [] // The options available for this filter.
                var vals = []    // The values to pre-set if the user has constraints applied on this filter.
                var constraints = this.constrainedValues(this.tile_query_param) // The constraints the user has on this filter.

                for (var i = 0; i < response.data.length; i++) {
                    if (constraints.includes(response.data[i]['code'])) {
                        // Only add options the user is allowed to filter upon.
                        options.push(response.data[i])

                        // Spatial filters need to be able to visualise all of Australia's road/rail
                        // network for say cross border movements, so don't enforce the constrained value.
                        //(i.e allow them to still select their constrained value(s) from the list for filter purposes)
                        if (!this.isSpatialFilter) {
                            vals.push(response.data[i]['code'])
                        }
                    }
                }

                // If the user only is only able to access 1 filter value due to constraints.
                if (vals.length == 1) {
                    this.value = vals;              // Then set the value
                    options[0]['disabled'] = true   // and disable the value in the dropdown list so they can't unselect it.
                }

            } else {
                // If unconstrained we can use all options.
                options = response.data;
            }

            vm.options = options; // Set the options.
            vm.isLoading = false; // Enable the filter.


        }, (error: any) => {
            console.log(error);
        });

    }


    //-----------------------------------
    //--------  Spatial Filter  ---------
    //-----------------------------------

    get map() {
        return this.getMap;
    }

    get tileLayerFillsId(): string {
        return this.tileLayer + '-fills';
    }

    get tileLayerFillsHighlightedId(): string {
        return this.tileLayer + '-fills-highlighted';
    }

    get tileLayerBordersId(): string {
        return this.tileLayer + '-borders';
    }

    get selectedCodeFilter(): any {
        var codes = this.value.map(function (item: any) {
            return item
        });
        codes.splice(0, 0, 'in');
        codes.splice(1, 0, 'code');
        return codes;
    }

    private popup = new mapboxgl.Popup({
        closeButton: false
    });


    @Watch('value', { deep: true })
    onValueChanged(val: any, oldVal: any) {

        // If it is a spatial filter
        if (this.isSpatialFilter && (!isNullOrUndefined(this.options[0]) && this.options[0]['disabled'] !== true)) {

            // Display a polygon on the map representing the selection
            this.ensureBordersLoaded();
            this.map.setFilter(this.tileLayerBordersId, this.selectedCodeFilter);
        }
    }


    @Watch('isSpatialSelecting')
    onSelectFeatures() {

        if (this.isSpatialSelecting) {// layers to help with chosing a feature
            this.ensureBordersLoaded(); // Ensure the layer containing the borders is loaded
            this.displaySelectionLayers();
            this.map.setFilter(this.tileLayerBordersId, null);  // Remove any filtering so all borders become visible
        } else {
            this.removeSelectionLayers();
            this.map.setFilter(this.tileLayerBordersId, this.selectedCodeFilter);
        }
    }


    ensureBordersLoaded() {

        if (!this.map.getLayer(this.tileLayerBordersId)) {
            this.map.addLayer(bordersStyle(this.tileLayerBordersId, this.tileSource, this.tileLayer));
        }
    }


    displaySelectionLayers() {

        this.map.addLayer(selectedBordersStyle(this.tileLayerFillsId, this.tileSource, this.tileLayer));
        this.map.addLayer(highlightedStyle(this.tileLayerFillsHighlightedId, this.tileSource, this.tileLayer));


        // Add the clicked feature to the filter
        this.map.on('click', this.tileLayerFillsId, this.onFillsClicked);

        // Highlight the feature and display a popup for the feature being hovered
        this.map.on('mousemove', this.tileLayerFillsId, this.onFillsMousemove);

        // Unhighlight and remove popup
        this.map.on('mouseleave', this.tileLayerFillsId, this.onFillsMouseleave);
    }


    removeSelectionLayers() {

        // Remove the events
        this.map.off('click', this.tileLayerFillsId, this.onFillsClicked);
        this.map.off('mousemove', this.tileLayerFillsId, this.onFillsMousemove);
        this.map.off('mouseleave', this.tileLayerFillsId, this.onFillsMouseleave);

        // Remove the layers
        [
            this.tileLayerFillsId,
            this.tileLayerFillsHighlightedId
        ].forEach(layer => {
            this.map.removeLayer(layer);
        });
    }


    onFillsClicked(e: any) {
        let value = e.features[0].properties.code;
        let val = this.value;
        if (isNullOrUndefined(val)) {
            val = [value];
        } else {
            if (!val.includes(value)) {
                let val2 = [...val];
                val2.push(value);
                val = val2;
            }
        }
        this.value = val;

        this.isSpatialSelecting = false;
    }


    onFillsMousemove(e: any) {

        // Change the cursor style as a UI indicator.
        this.map.getCanvas().style.cursor = 'pointer';

        // Single out the first found feature.
        var feature = e.features[0];

        this.map.setFilter(this.tileLayerFillsHighlightedId, ['==', 'code', feature.properties.code]);

        // Display a popup with the name of the region
        this.popup.setLngLat(e.lngLat)
            .setText(feature.properties.name)
            .addTo(this.map);
    }


    onFillsMouseleave() {

        this.map.getCanvas().style.cursor = '';
        this.popup.remove();
        //Note: this event causes things to flicker.  If can't find out why... perhaps debounce this code?
        if (this.map.getLayer(this.tileLayerFillsHighlightedId)) { // After clicking and removing this layer, mouseleave event seems to be still called
            this.map.setFilter(this.tileLayerFillsHighlightedId, ['in', 'code', '']);
        }
    }
}



