








































































































































































































































































































































































































































































































// import { Component, Prop, Vue } from 'vue-property-decorator';
import Component from 'vue-class-component';
import { Prop, Vue, Watch } from 'vue-property-decorator';
import { State, Action, Getter } from 'vuex-class';

import { bus } from '@/pages/transitweb/main'

import router from "@/router";

import { DatasetState, DensityType } from '@/store/datasets/types';
import { AuthState } from '@/store/auth/types';
import { AssistantState } from '@/store/assistant/types';

import { isNullOrUndefined } from 'util';

//import variables from '@/pages/app'

import TermsViewer from '@/components/auth/TermsViewer.vue';

const namespace: string = 'datasets';

@Component({
    components: {
        TermsViewer,
    },
})

export default class NavBar extends Vue {

    @State('datasets') datasets!: DatasetState;
    @Action('setDataset', { namespace }) setDataset: any;
    @Action('setDatasetType', { namespace }) setDatasetType: any;
    @Action('datasets/setNav') setNav: any;

    @State('auth') auth!: AuthState;
    @Action('auth/logout') signout: any;
    @Action('auth/MFA_deactivate') deactivate: any;
    @Action('auth/refreshToken') refreshToken: any;
    @Action('datasets/getMetadata') getMetadata: any;
    @Getter('auth/isAuthenticated') isAuthenticated?: boolean;
    @Getter('auth/isAnonymousMode') isAnonymousMode?: boolean;
    @Getter('auth/isAuthenticatedAndMfaActivated') isAuthenticatedAndMfaActivated?: boolean;
    @Getter('auth/displayName') displayName!: string;
    @Getter('auth/isMfaRequired') isMfaRequired?: boolean;
    @Getter('auth/isAdmin') isAdmin?: boolean;
    @Getter('auth/isScenarioUser') isScenarioUser!: boolean;
    @Getter('auth/isAcsUser') isAcsUser!: boolean;
    @Getter('auth/isAllowedSensitiveInfo') isAllowedSensitiveInfo!: boolean;
    @Getter('auth/accessTokenLifeMs') accessTokenLifeMs!: any;
    @Getter('auth/accessToken') accessToken!: any;


    @State('assistant') assistant!: AssistantState;
    @Action('assistant/setStandAlone') setStandAlone: any;
    @Getter('assistant/assistantName') assistantName!: string;

    @Getter('events/eventDensityType') eventDensityType!: DensityType;

    version: string = '';

    DensityType: any = DensityType;

    user_guide_info = [
        ['user_guide_transitweb', 'TraNSIT Web'],
        ['user_guide_dashboard', 'Supply Chain Benchmarking Dashboard'],
        //['user_guide_events', this.isAcsUser, 'Event Maps'],
        //['user_guide_scenarios', this.isScenarioUser, 'Scenario Functionality'],
    ];


    showTermsDialog: boolean = false;
    terms_slug: string = "terms-of-service";

    created() {
        // Close Terms Dialog.
        bus.$on('close_terms_dialog', (val: boolean) => {
            this.showTermsDialog = val;
        });
    }

    get nav(): string {
        return this.datasets.nav;
    }
    set nav(val: string) {
        this.setNav(val);
    }

    deactivate_mfa() {

        var vm = this;

        this.deactivate({})
            .then(function (resp: any) {
                console.log(resp);
                vm.$router.push("/MFA")

            })
            .catch(function (err: any) {
                console.log(err)
            });
    }


    get densityType(): DensityType {
        return this.datasets.type;
    }


    @Watch('nav', { deep: false })
    onNavChanged(val: any, oldVal: any) {
        if (val == DensityType.Road || val == DensityType.Rail || val == DensityType.Sea || val == DensityType.Air) {
            this.setDatasetType(val);
            if (oldVal === 'Benchmarking' || oldVal === 'Explorer') {
                this.$router.push('/');
            }
        }
        else if (val == 'Events') {
            this.setDatasetType(this.eventDensityType)
            if (oldVal === 'Benchmarking' || oldVal === 'Explorer') {
                this.$router.push('/');
            }
        }
        else if (val == 'Explorer') {
            this.$router.push('/explorer');
        }
        else if (val == 'Benchmarking') {
            this.$router.push('/benchmark')
        }
    }

    @Watch('datasets', { deep: true })
    onDensityTypeChanged(val: any, oldVal: any) {
        if (val == DensityType.Road || val == DensityType.Rail || val == DensityType.Sea || val == DensityType.Air) {
            this.nav = val.type;
        }
    }

    @Watch('datasets.dataset', { deep: true })
    onDatasetDefined(val: any, oldVal: any) {
        this.fetchMetadata();
    }

    @Watch('isAuthenticated')
    onAuthenticated(val: boolean, oldVal: boolean) {
        this.fetchMetadata();
    }

    fetchMetadata() {
        if (this.datasets.dataset !== undefined && this.isAuthenticated) {
            // Get current baseline metadata.
            this.getMetadata({ dataset: this.datasets.dataset, endpoint_type: 'web' })
                .then(() => {
                    this.version = this.datasets.metadata.baseline_name;
                    console.log('Version:', this.version);
                })
                .catch((error: any) => {
                    //console.error(error);
                });
        }
    }

    isTokenExpired: boolean = false;
    timeoutID: any;
    @Watch('auth.access_token', { deep: true })
    onAccessTokenUpdated(val: any, oldVal: any) {
        if (this.isAuthenticated) {
            var exp = this.accessTokenLifeMs(new Date())
            //console.log("Token Expires in:", exp);
            //console.log(this.accessToken)
            clearTimeout(this.timeoutID);
            this.isTokenExpired = false;
            this.timeoutID = setTimeout(async () => {
                console.log("Access Token Expired!");
                this.isTokenExpired = true;
                try {
                    console.log("Refreshing token on expiry...")
                    await this.refreshToken();
                } catch (_error) {}
            }, exp);
        }
    }

    return_assistant() {
        this.setStandAlone(false);
        bus.$emit('assistant_dialog', true)
    }


    onDatasetChanged(val: any) {
        this.setDataset(val)
    }


    signOut() {
        console.log('Signing Out');
        this.signout();
        router.push("/signin");
    }

    trackClick(label: string) {
        (Vue as any).gtm.trackEvent({
            event: 'gui',
            category: 'links',
            action: 'Link Clicked',
            label: label,
            //value: payload.value,
            noninteraction: true,
        });
        //console.log('Link Clicked', label)
    }


    provideFeedback() {
        (window.location as any) = "mailto:support@transit.csiro.au?subject=TraNSIT Web Feedback&body=Please tell us about your experience...";
        this.trackClick('Provide Feedback')
    }

    about() {
        (window as any).open("https://www.csiro.au/TRANSIT");
        this.trackClick('About')
    }

    ai_assistant() {
        bus.$emit('assistant', {subject: 'transit_web'})
    }

    news_articles() {

        // Doco on querying the Google Search API here: https://www.google.com/support/enterprise/static/gsa/docs/admin/current/gsa_doc_set/xml_reference/request_format.html

        if (this.nav == 'Benchmarking') {
            (window as any).open("https://www.google.com/search?q=%22Supply+Chain+Benchmarking+Dashboard%22&tbs=sbd:1&tbm=nws");
        } else {
            (window as any).open("https://www.google.com/search?q=%22Transport+Network+Strategic+Investment+Tool%22&tbs=sbd:1&tbm=nws");
        }
        this.trackClick('News Articles')
    }

    publications() {

        // Doco on querying the Google Search API here: https://www.google.com/support/enterprise/static/gsa/docs/admin/current/gsa_doc_set/xml_reference/request_format.html

        (window as any).open("https://scholar.google.com.au/scholar?hl=en&as_sdt=0,5&q=%E2%80%9CTransport+Network+Strategic+Investment+Tool%E2%80%9D");
        this.trackClick('Publications')
    }

    metadata() {
        (window as any).open('./docs/commodity_metadata.xlsx');
        this.trackClick('Commodity Metadata')
    }

    faq() {
        (window as any).open('./docs/faq_transitweb.pdf');
        this.trackClick('FAQ')
    }

    user_guide(name: any) {
        (window as any).open('./docs/' + name + '.pdf');
        this.trackClick('User Guide')
    }

    networks() {
        (window as any).open('./docs/transit_networks.pdf');
        this.trackClick('Network Information')
    }

    road_closure_sources_info() {
        bus.$emit('road_closure_sources', true);
    }

    scenario_studio() {
        bus.$emit('scenario_studio', true);
    }

    task_list() {
        bus.$emit('task_list_dialog', true);
    }

    user_account() {
        this.$router.push("/account")
    }

    get devbuild() {
        return !isNullOrUndefined(process.env.VUE_APP_COMMIT_COUNT)
    }

    get subtitle() {
        return `v0.0.${process.env.VUE_APP_COMMIT_COUNT}-dev`;
    }

}


