export interface ILayerVisibility { layer: string, value: boolean }

export interface MapState {
    layerStyle: string;
    marker?: lngLat;
    featureOfInterest?: any;
    hiddenLayers: string[];
    flyTo: number[];
}

export interface lngLat {
    lng: number, lat: number;
}

export enum Layers {
    Enterprises = 'transit-enterprises',
    Roads = "transit-roads",
    RoadsBackground = "transit-roads-bg",
    Rail = "transit-rail",
    RailBackground = "transit-rail-bg",
    Sea = "transit-sea",
    SeaBackground = "transit-sea-bg",
    RoadEventsBaseline = "transit-road-events-baseline",
    RoadEventsScenario = "transit-road-events-scenario",
    DeltaTrailers = "transit-events-delta-trailers",
    ClosedRoads1 = "transit-events-closed-roads-1",
    ClosedRoads7 = "transit-events-closed-roads-7",
    ClosureCategories = "transit-closure-categories",
    BlockedEnterprisesOrigin = "transit-events-blocked-enterprises-orig",
    BlockedEnterprisesDestination = "transit-events-blocked-enterprises-dest",
    BlockedEnterprisesUnaffected = "transit-events-blocked-enterprises-unaffected",
    BlockedEnterprisesPartial = "transit-events-blocked-enterprises-partial",
    BlockedEnterprisesTotally = "transit-events-blocked-enterprises-totally",
    BlockedEnterprisesImpactedBy = "transit-events-blocked-enterprises-impactedby",
    EventsEnterprisesBaseline = "transit-events-enterprises-baseline",
    HighlightSegments = "transit-highlight-segments",
    HighlightEnterprises = "transit-highlight-enterprises"
}

export enum Sources {
    Enterprises = 'enterprises-source',
    Roads = "roads-source",
    RoadsBackground = "roads-source-bg",
    Rail = "rail-source",
    RailBackground = "rail-source-bg",
    Sea = "sea-source",
    SeaBackground = "sea-source-bg",
    RoadEventsBaseline = "road-events-baseline-source",
    RoadEventsScenario = "road-events-scenario-source",
    DeltaTrailers = "events-delta-trailers-source",
    ClosedRoads = "events-closed-roads-source",
    ClosureCategories = "closure-categories-source",
    BlockedEnterprisesOD = "events-blocked-enterprises-od-source",
    BlockedEnterprises = "events-blocked-enterprises-source",
    BlockedEnterprisesImpactedBy = "events-blocked-enterprises-impactedby-source",
    EventsEnterprisesBaseline = "events-enterprises-baseline-source",
    HighlightSegments = "highlight-segments-source",
    HighlightEnterprises = "highlight-enterprises-source"
}
