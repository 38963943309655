

































import { Component, Vue } from 'vue-property-decorator';
import { State, Action, Getter } from 'vuex-class';

import router from "@/router";
import { bus } from '@/pages/transitweb/main'

import { AuthState, AuthStatus } from '@/store/auth/types';
import { AssistantState } from '@/store/assistant/types';
import Assistant from '@/components/assistant/Assistant.vue';
import NavBar from '@/components/NavBar.vue';
import Tasks from '@/components/tasks/Tasks.vue';

import { endpoints } from "@/endpoints";

import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
library.add(fas)
Vue.component('font-awesome-icon', FontAwesomeIcon)

import { isNullOrUndefined } from 'util';

@Component({
    components: {
        NavBar,
        Assistant,
        Tasks,
    }
})


export default class App extends Vue {

    densityType: string = 'Road';

    @State('auth') auth!: AuthState;
    @Action('auth/logout') logout: any;
    @Action('auth/refreshToken') refreshToken: any;
    @Getter('auth/isAuthenticated') isAuthenticated?: boolean;
    @Getter('auth/isAccessTokenExpired') isAccessTokenExpired!: any;

    @State('assistant') assistant!: AssistantState;
    @Action('assistant/askQuestion') askQuestion: any;

    @Getter('assistant/assistantName') assistantName!: string;

    assistant_dialog: boolean = false;
    task_list_dialog: boolean = false;
    assistant_warning: boolean = false;

    created() {

        var app = this;

        // Logout if user does not have permissions (i.e. their token has expired).
        // This is for axios calls only such as all API requests. (Not routes)
        // The first argument is the success function (synchronous), the second argument is the error function (asynchronous - requires promise).
        // Note: Does not pick up mapbox 401 tile errors, due to the way mapbox transforms the headers.
        Vue.axios.interceptors.response.use(

            (response) => {
                return response
                // Handles typical redirects... not actually used anymore.
                //if (response.request.responseURL !== response.config.url) {
                //    window.location.href = response.request.responseURL
                //} else {
                //    return response
                //}
            },

            async (error) => {

                //console.log(error);
                const originalRequest = error.config;

                if (error.response.status === 401) {
                    if (originalRequest.url === endpoints.authRefreshTokenUrl) {
                        app.logout();
                        router.push("/signin");
                        return error;
                    } else if (!originalRequest._retry && error.response.data.error != 'Invalid or expired code.') {
                        originalRequest._retry = true;
                        try {
                            console.log("Refreshing token on post-request...")
                            await app.refreshToken();
                            originalRequest.headers['Authorization'] = 'Bearer ' + app.auth.access_token;
                            return Vue.axios(originalRequest);
                        } catch (_error) {
                            return Promise.reject(_error);
                        }
                    }
                    // Redirects are considered an error according to axios default settings, the default validationStatus can be changed in main.ts,
                    // but considering it a successful response doesn't make sense either as it didn't "actually" fetch the intended resource.
                    // Note: this interceptor saves having to write a "redirection on error" for all our endpoint responses.
                    // Also MapBox does not use Axios for requests so this interceptor will nor redirect on new tile fetches,
                    // but will however display blank tiles until an axios request is triggered.
                } else if (error.response.status === 300) { 
                    let location = error.response.headers.location;
                    if ((app.$router as any).history.current.path !== location) {
                        console.log("Redirecting to: " + location)
                        this.$router.push(location);
                    }
                }
                return Promise.reject(error);
            });


        // Add token into headers before requests are made.
        Vue.axios.interceptors.request.use(
            async (request) => {
                if (app.isAuthenticated) {

                    // Get a new access token before making the request if it seems like it may be expired.
                    // This is just to attempt to avoid 'double' requests, that need to take place if
                    // the refreshing of the token were only to be handled post-request.
                    if ((app.isAccessTokenExpired(new Date()) && request.url != endpoints.authRefreshTokenUrl)) {

                        try {
                            console.log("Refreshing token on pre-request...")
                            await app.refreshToken();
                        } catch (_error) {
                            return Promise.reject(_error);
                        }
                    }

                    // Inject the current store token into the headers if authenticated.
                    request.headers.common['Authorization'] = 'Bearer ' + app.auth.access_token;
                    request.headers.common['X-Username'] = app.auth.user.username;
                }

                return request;
            });

        bus.$on('assistant_dialog', (data: boolean) => {
            this.assistant_dialog = data;
        })

        bus.$on('task_list_dialog', (data: boolean) => {
            this.task_list_dialog = data;
        })

        bus.$on('assistant', (data: any) => {

            //var assistant = this.$refs['assistant'] as Assistant

            if (!this.assistant.isStandAlone) {
                this.assistant_dialog = true;
            } else {
                this.assistant_warning = true;
            }
            if (isNullOrUndefined(data.subject)) {
                data.subject = 'general'
            }
            if (!isNullOrUndefined(data.content)) {
                this.askQuestion(data)
            }

        })

        bus.$on('task_submitted', (data: any) => {
            this.task_list_dialog = true;   // Show the task list.
            bus.$emit('add_task', data)     // Add the task to the list.
        })

    }
}


