













import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

import VuePlotly from '@statnett/vue-plotly';

import { mixins } from 'vue-class-component';
import QueryEndpointData from './dataquery-mixin';

@Component({
    components: {
        VuePlotly,
    }
})
export default class BorderCrossingsBarChart extends mixins(QueryEndpointData) {


    mounted() {
        // Some dummy border crossing values that come from Apples in the prototype.
        // Note: Made the last value 5 % rather than 0.03 % so it could be seen for now...
        // Probably need a different way to visualize figures? (Pie Chart Maybe?)
        this.data = [
            {crossings: 0 ,percentage: 52},
            {crossings: 1 ,percentage: 27},
            {crossings: 2 ,percentage: 15},
            {crossings: 3 ,percentage: 5}
        ];
    }


    @Watch('updateRequired')
    reloadData(newVal: any, oldVal: any) {
        // Reload the data
        //this.loadData(this.densityMapUrl);
        // Put some other dummy data in there on update.
        this.data = [
            {crossings: 0 ,percentage: 47},
            {crossings: 1 ,percentage: 30},
            {crossings: 2 ,percentage: 4},
            {crossings: 3 ,percentage: 19}
        ];
    }


    get crossingsData(): [any] {
        // Get the plotly data object for State & Territory
        // crossings for a horizontal bar char display.

        return this.data.map((value: any) => {

            return {
                type: 'bar',
                orientation: 'h',
                name: value.crossings,
                y: ["State & Territory<br>Border Crossings"],
                x: [value.percentage],
                marker: {
                    opacity: 0.7,
                },
                text: [`${value.percentage} %`],       // Value inside the bar
                textposition: "inside",     // Places text inside the bar
                insidetextanchor: "middle", // Aligns the value to the center
                textfont: {
                    color: "white"          // Force text color to white (it can render black on light backgrounds)
                },
            }
        })
    }


    get annotations(): [any] {
        // Get the annotations for the number of States & Territories
        // and proprtion their x location according to their data sizes.

        var total = 0;

        return this.data.map((value: any) => {

            total = total + value.percentage;
            return {
                //xref: "x",
                //yref: "y domain",
                x: total - (value.percentage / 2), // Subtract half the x value to put it in the center.
                y: 0.63,
                //yanchor: "bottom",
                text: value.crossings == 0 ? "0 (Intrastate)" : value.crossings == 1 ? "1 (Neighbouring)" : value.crossings,
                showarrow: false,
            }
        })
    }


    get crossingsLayout(): any {
        // Get the plotly layout object for State & Territory
        // crossings for a horizontal bar char display.

        return {
            barmode: 'stack',
            showlegend: false,
            margin: { l: 130, r: 90, b: 0, t: 0 },
            font: {
                size: 14
            },
            xaxis: {
                showgrid: false, // Removes grid lines
                zeroline: false, // Removes the zero line
                range: [-100 * 0.01, 100],
            },
            height: 60,
            autosize: true,
            dragmode: false,
            hovermode: false,
            annotations: this.annotations
        }
    }
}


