
import { Component, Prop, Vue } from 'vue-property-decorator';
import { State, Action, Getter } from 'vuex-class';

import { DatasetState } from '@/store/datasets/types';

import buildUrl from 'build-url';

@Component({
    components: {
    }
})
export default class EndpointData extends Vue {

    @State('datasets') datasets!: DatasetState;

    init: boolean = false;
    loading: boolean = false;
    data: any = [];


    get dataset(): any {
        return this.datasets.dataset;
    }


    getQueryParams(): any {
        // Override this to provide a specific set of query params for a tool.
        throw new Error("getQueryParams is not implemented!!");
    };


    loadData(url: string, params?: any) {
        // Load the data from an endpoint using the query params
        // defined by a concrete implementation of a tool.

        console.log("Loading Data...");
        this.loading = true;

        // Get the query params from the overwridden method.
        var p = this.getQueryParams();

        // Add in any additional params.
        if (params) {
            p = Object.assign({}, p, params);
        }

        // Fetch the data from the endpoint.
        Vue.axios({
            url: buildUrl(url, { queryParams: p }),
        }).then((response: any) => {
            this.data = response.data;
            this.init = true;
            this.loading = false;
        }, (error: any) => {
            console.log(error);
            this.init = true;
            this.loading = false;
        });

    }
}
