




























































































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { State, Action, Getter } from 'vuex-class';
import { bus } from '@/pages/transitweb/main'

import { MapState } from '@/store/map/types';

import { GChart } from 'vue-google-charts'

import InfoPanelDashboard from './InfoPanelDashboard.vue';
import InfoPanelCharts from './InfoPanelCharts.vue';
import CO2 from './CO2.vue';

@Component({
    components: {
        GChart,
        CO2,
        InfoPanelDashboard,
        InfoPanelCharts
    }
})
export default class InfoPanelSea extends Vue {

    @Prop() private info!: any;

    @Prop() private chart_data!: any;
    @Prop() private hidecounts!: boolean;
    @Prop() private statisticType!: string;

    @State('map') mapstate!: MapState;

    @Getter('auth/isAllowedSensitiveInfo') isAllowedSensitiveInfo!: boolean;

    @Action('map/setFlyTo') setFlyTo: any;

    get sea(): any {
        return this.info || this.mapstate.featureOfInterest.properties;
    }

    //gauges = {
    //    '610': 'Tramway Gauge: 610 mm',
    //    '1067': 'Narrow Gauge: 1067 mm',
    //    '1435': 'Standard Gauge: 1435 mm',
    //    '1600': 'Broad Gauge: 1600 mm',
    //    'Dual': 'Dual Gauge',
    //    ' ': 'Unknown Gauge',
    //}

    getName(name: boolean) {
        if (!this.sea) { return '' }
        return this.sea.sea_name != null ? this.splitSeaNameByDash(this.sea.sea_name, name) : 'Undefined'
    }

    splitSeaNameByDash(str: any, name: boolean): { beforeDash: string; afterDash: string } {
        var parts = str.split('-');
        var beforeDash = parts[0].trim();// This is channel type
        var afterDash = parts[1] ? parts[1].trim() : ''; // This is channel name

        if (name) { return afterDash } else { return beforeDash };
    }

    onClose() {
        //this.expansionPanel = null;
        this.$emit('closed', this)
    }

    onCriticalLink() {
        var e: any = [this.sea.link_id]
        //this.$emit('critical_link', 'critical_link', e, true)
        bus.$emit('add_special_filter', 'critical_link', e, true)
    }

    onCloseLink() {
        var e = {
            task: {
                name: 'Close ' + this.sea.aea_name + ' for segment ' + this.sea.link_id,
            }
        }
        bus.$emit('create_task', e)
    }

    onCloseSeaLine() {
        var e = {
            task: {
                name: 'Close ' + this.sea.sea_name,
            }
        }
        bus.$emit('create_task', e)
    }

    onAddNameFilter() {
        var e: any = [this.sea.sea_name]
        bus.$emit('append_name_filter', 'sealane', e)
        this.onClose()
    }

}


