
import { Layers, Sources } from '@/store/map/types';

const roadsShadow = 'rgba(255, 255, 255, 0.8)';

var roadsLayout = {
    'line-cap': 'round',
    'line-join': 'round',
};

var road_width = () => {

    const PROPERTY_ID = 'road_rank';

    const road_width = [
        "interpolate", ["linear"], ["zoom"],
        // when zoom is 8, set each links width to a value related to its "road_rank" property
        7, ['case',
            ['==', ['get', PROPERTY_ID], 1], 3,   // rank 1
            ['==', ['get', PROPERTY_ID], 2], 2.5, // rank 2
            1,                                    // the rest
        ],
        // when zoom is 16, set each links width to a value related to its "road_rank" property
        14, ['case',
            ['==', ['get', PROPERTY_ID], 1], 14,  // rank 1
            ['==', ['get', PROPERTY_ID], 2], 12,  // rank 2
            8,                                    // the rest
        ],
        18, ['case',
            ['==', ['get', PROPERTY_ID], 1], 20,  // rank 1
            ['==', ['get', PROPERTY_ID], 2], 16,  // rank 2
            12,                                   // the rest
        ]
    ]

    return road_width;
}

export var roadStyle = (source: Sources) => {

    return {
        id: Layers.Roads,
        type: 'line',
        source: source,
        'source-layer': 'road',
        layout: roadsLayout,
        paint: {
            'line-width': road_width(),
        }
    }
}

export var roadShadowsStyle = (source: Sources) => {

    return {
        id: Layers.RoadsBackground,
        type: 'line',
        source: 'roads-source-bg',
        'source-layer': 'road',
        layout: roadsLayout,
        paint: {
            'line-width': road_width(),
            'line-color': roadsShadow,
        }
    }
}


export var roadEventsBaselineStyle = (source: Sources) => {

    return {
        id: Layers.RoadEventsBaseline,
        type: 'line',
        source: source,
        'source-layer': 'road_events_baseline',
        layout: roadsLayout,
        paint: {
            'line-width': road_width(),
        }
    }
}

export var roadEventsScenarioStyle = (source: Sources) => {

    return {
        id: Layers.RoadEventsScenario,
        type: 'line',
        source: source,
        'source-layer': 'road_events_scenario',
        layout: roadsLayout,
        paint: {
            'line-width': road_width(),
        }
    }
}


export var roadEventsDeltaTrailersStyle = (source: Sources) => {

    return {
        id: Layers.DeltaTrailers,
        type: 'line',
        source: source,
        'source-layer': 'deltatrailers',
        layout: roadsLayout,
        paint: {
            'line-width': road_width(),
            'line-color': {
                property: 'trailer_loads',
                //type: 'exponential',
                default: '#000000',
                stops: [
                    [-100000, '#000000'],
                    [-500, '#007206'],
                    [-350, '#2A8E0A'],
                    [-250, '#65AB14'],
                    [-150, '#95C617'],
                    [-51, '#C4E31D'],
                    [-50, '#828282'],
                    [0, '#828282'],
                    [50, '#828282'],
                    [51, '#F9DF1A'],
                    [150, '#FDBD11'],
                    [250, '#FF9A0B'],
                    [350, '#FE710B'],
                    [500, '#FC3B09'],
                    [100000, '#000000'],
                ],
            }
        }
    }
}


export var roadEventsClosedRoadsStyle = (source: Sources, layerId: Layers,  filter: any) => {

    return {
        id: layerId,
        type: 'line',
        source: source,
        'source-layer': 'closedroads',
        layout: roadsLayout,
        paint: {
            'line-width': 2,
            'line-color': {
                property: 'days_closed',
                default: '#000000',
                stops: [
                    [7, '#F7455D'],
                    [8, '#964B00'],
                ]
            }
        },
        filter: filter
    }
}

export var highlightSegmentsStyle = (layerId: string, sourceId: string) => {

    return {
        id: layerId,
        type: 'line',
        source: sourceId,
        'source-layer': 'scenario-highlight-segments',
        layout: {
            'line-cap': 'round',
            'line-join': 'round',
        },
        paint: {
            'line-width': {
                stops: [
                    [7, 3],
                    [14, 14],
                    [18, 20]

                ],
            },
            'line-color': 'red',
            'line-opacity': 1,
        }
    };
};

export var roadEventsClosureCategoriesStyle = (source: Sources) => {

    return {
        id: Layers.ClosureCategories,
        type: 'symbol',
        source: source,
        'source-layer': 'closurecategories',
        'layout': {
            'visibility': 'visible',
            //'icon-image': 'caution_icon',
            'icon-image': ['match',
                ['get', 'closure_category'],
                1, 'water_icon',
                2, 'fire_icon',
                'caution_icon' // Default image for all other categories
            ],
            'icon-allow-overlap': false,
            'symbol-sort-key': ['get', 'count'], // Sort features based on 'count', this allows icons with lower count to be drawn first so they are still visible against icons with high count
            //'icon-offset': [0, -20],
            'icon-size': 0.6,
        },

        'paint': {
            'icon-halo-width': 0.5,
            'icon-halo-color': '#000000',
            'icon-opacity': 1,
            'icon-color': ['match', // Use the 'match' expression: https://docs.mapbox.com/style-spec/reference/expressions/#match
                ['get', 'closure_category'],
                1,// Flood
                '#6677bb',
                2,// Fire
                '#f56342',
                3,// Other natural disaster
                '#FFC300',
                '#FFC300' // Default color for all other categories
            ],
        },
    }
};
