















































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { State } from 'vuex-class';

import { DatasetState } from '@/store/datasets/types';

import { bus } from '@/pages/transitweb/main'

import * as scenario_layers from '@/components/layers/scenarios';
import { Scenario, ActionType } from './types'
import { endpoints } from "@/endpoints";

import buildUrl from 'build-url';

import {
    MglMap,
} from 'vue-mapbox'

import MglVectorLayer from '@/components/MglVectorLayer.vue' // Use our custom version of the vector layer.

var mapboxgl = require('mapbox-gl');
var geojsonExtent = require('@mapbox/geojson-extent')

@Component({
    components: {
        MglMap,
        MglVectorLayer,
    },
})

export default class PreviewMap extends Vue {

    @State('datasets') datasets!: DatasetState;
    @Prop() private scenario!: Scenario;

    mapboxAccessToken: any = 'pk.eyJ1IjoiYm9uMTMyIiwiYSI6ImNqdXRhYmw1OTA1eGUzeW5yZGo3OWZmankifQ.RYbaSeGdz3Nq_hIGWXrpSw';
    mapStyle = 'mapbox://styles/mapbox/light-v10'; // style URL

    center = [134.0, -28.3];
    bounds_australia = [
        [60, -61], // Southwest coordinates.
        [207, 20]  // Northeast coordinates.
    ];

    map_layers: any = [];
    loading_map: boolean = false;

    get dataset(): any {
        return this.datasets.dataset;
    }

    created(){

        // Need to resize the map when it first becomes visible. This is because it is initialized into the DOM, when the map is not actually visible which then causes issues.
        bus.$on('scenario_studio', (e: any) => {
            if (e){
                Vue.nextTick(() => {
                    this.$data.map.resize();
                });
            }
        })
    }

    // Event handlers seem to need to be turned off on destroyed... Otherwise if you logout then login 2 (or more depending on how many times you logout) event handlers will be present?
    destroyed(){
        bus.$off('scenario_studio');
    }

    // A watcher for making adjustments when the scenario is updated.
    @Watch('scenario')
    onScenarioChanged(val: Scenario, oldVal: Scenario) {

        // Update the map to refect the new scenario changes.
        this.refreshMap();
    }

    // Create the map layers for all actions in the scenario.
    get layers() {

        // Layers are a vector tile layer for each action type.
        var scenarioSegmentLayerId = 'scenario-segment-layer';
        var scenarioSegmentSourceId = 'scenario-segment-source';

        var scenarioEnterpriseLayerId = 'scenario-enterprise-layer';
        var scenarioEnterpriseSourceId = 'scenario-enterprise-source';


        // Return all the layers.
        return [
            
            {
                // Scenario segment layer.
                layerId: scenarioSegmentLayerId,
                layer: scenario_layers.scenarioSegmentsStyle(scenarioSegmentLayerId, scenarioSegmentSourceId),
                sourceId: scenarioSegmentSourceId,
                source: {
                    tiles: [buildUrl(endpoints.scenarioActionsLayerUrl(this.dataset, 'segments'))],
                    tolerance: 40,
                    buffer: 40,
                },
            },

            {
                // Scenario enterprise layer.
                layerId: scenarioEnterpriseLayerId,
                layer: scenario_layers.scenarioEnterprisesStyle(scenarioEnterpriseLayerId, scenarioEnterpriseSourceId),
                sourceId: scenarioEnterpriseSourceId,
                source: {
                    tiles: [buildUrl(endpoints.scenarioActionsLayerUrl(this.dataset, 'enterprises'))],
                    tolerance: 40,
                    buffer: 40,
                },
            },
        ];
    };

    // Add the scenario to the body, and the Auth token to the headers in all Mapbox Tile requests.
    // Note: Does not use Vue.axios that we have configured, so interceptors do not pick up 401 errors.
    addScenarioInputsAndJWTToken(url: string, resourceType: any) {
        
        if (resourceType == 'Tile' && !url.includes('mapbox.com')) {

            return {
                url: url,
                headers: {
                    'Authorization': 'Bearer ' + this.$store.state.auth.access_token,
                    'X-Username': this.$store.state.auth.user.username,
                    'Content-Type': 'application/json'
                },
                method: 'POST',
                body: JSON.stringify(this.scenario)
            }
        }
    }

    // Refresh the map, using the provided scenario.
    refreshMap() {

        this.loading_map = true;

        // Remove existing layers.
        this.map_layers = [];

        // Need to do this on nextTick so the layers can be successfully removed when mgl-vector-layers are destroyed.
        Vue.nextTick(async () => {

            // Fit map to Australia if no actions applied.
            var bounds = this.bounds_australia

            // Only update map layers if actions exist.
            if (this.scenario.actions.length > 0) {

                // Create layers. Note: layers are independent of a scenario (as the scenario is posted in the request body).
                this.map_layers.push(...this.layers);

                // Update the bounds with the bounds of the scenario.
                let geojson = await this.getScenarioBounds(this.scenario)
                if (geojson){
                    bounds = geojsonExtent(geojson);
                }
            } 

            // Fit the map to the bounds.
            this.$data.map.fitBounds(bounds, {
                padding: 50, // Padding around the bounds.
                //easing: // Some animate function.
            });
        });

    };

    // Get scenario geometry data.
    getScenarioBounds(scenario: Scenario) {

        return new Promise((resolve, reject) => {
            Vue.axios({
                url: endpoints.scenarioBoundsUrl(this.dataset),
                data: scenario,
                method: 'POST'
            }).then(response => {
                resolve(response.data);
            }, (error: any) => {
                reject(error);
            });
        })
    };

    //-------------------------------------------
    //---------          Events        ----------
    //-------------------------------------------


    // The map has been initialized.
    onMapLoaded(e: any) {
        this.$data.map = e.map;
    }

    // Map is updating.
    onMapData(e: any) {
        if (e.mapboxEvent.tile) {
            this.loading_map = !this.$data.map.areTilesLoaded()
            this.$emit('map_loading', this.loading_map)
        }
    };

    // Map becomes idle.
    onMapIdle(e: any) {
        this.loading_map = !this.$data.map.areTilesLoaded()
        this.$emit('map_loading', this.loading_map)
    };
}

