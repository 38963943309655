












































































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { State, Action, Getter } from 'vuex-class';

import { bus } from '@/pages/transitweb/main'

import { FiltersState } from '@/store/filters/types';
import { DatasetState, DensityType } from '@/store/datasets/types';
import { AssistantState } from '@/store/assistant/types';

import TransitFilter from '@/components/filters/Filter.vue';

import { isNullOrUndefined } from 'util';

@Component({
    components: {
        TransitFilter
    }
})
export default class Filters extends Vue {

    @Prop() private getMap!: any;

    @Getter('auth/isAllowedSensitiveInfo') isAllowedSensitiveInfo!: boolean;
    @Getter('auth/isAdmin') isAdmin?: boolean;
    @Getter('auth/isConstrained') isConstrained: any; // Returns an anonymous function
    @Getter('auth/constrainedValues') constrainedValues: any; // Returns an anonymous function

    @State('filters') filters!: FiltersState;
    @Action('filters/removeUnusedFilters') removeUnusedFilters: any;
    @Action('filters/setFilterValue') setFilterValue: any;
    @Action('filters/setFilterParams') setFilterParams: any;
    @Action('filters/setFilterEnabled') setFilterEnabled: any;
    @Action('filters/setFiltersDirty') setFiltersDirty: any;
    @Getter('filters/filtersWithValues') filtersWithValues!: string[];
    @Getter('filters/isDirty') isFiltersDirty!: boolean;
    @Getter('filters/isFiltered') isFiltered!: boolean;
    @Getter('filters/disabled') isDisabled!: boolean;
    @Getter('filters/visible') isVisible!: boolean;
    @Getter('filters/isSpatialFilter') isSpatialFilter: any; // Returns an anonymous function
    @Getter('filters/filterWorksWith') filterWorksWith: any; // Returns an anonymous function
    @Getter('filters/filterHasValue') filterHasValue: any; // Returns an anonymous function
    @Getter('filters/filterUrl') filterUrl: any; // Returns an anonymous function
    @Getter('filters/hasUnconstrainedFilters') hasUnconstrainedFilters!: boolean;

    @State('assistant') assistant!: AssistantState;

    @State('datasets') datasets!: DatasetState;


    //@Watch('densityType')
    //onDensityTypeChanged(val: DensityType, oldVal: DensityType) {
    //    if (this.densityType === DensityType.Rail || this.densityType === DensityType.Road) {

    //        // Need to update commodity filter here with relevant query params - so the selection list updates when the density type is switched to Road or Rail.
    //        // At the moment, if Events is selected, commodity lists are updated using report_type parameter, it'd be better to replace it with density type.
    //        // It would be nicer to do all filters using density type and via store...
    //        this.setFilterParams({ filter: 'commodity', params: { density_type: this.densityType, } });
    //        this.refreshMap;
    //    }
    //}

    get densityType(): DensityType {
        return this.datasets.type;
    }

    created() {

        // Need to update commodity filter here with relevant query params - so the selection list updates.
        // this.setFilterParams({ filter: 'commodity', params: { density_type: this.densityType, } });

        bus.$on('append_name_filter', (filterId: string, value: any) => {
            let filter = this.$refs[filterId + '_filter'] as any;
            var options = (filter[0] as TransitFilter).options;
            var res = options.filter(function (option: any) {
                return option.code == value;
            });
            if (res.length > 0) {
                this.setFilterEnabled({ filter: filterId, value: true });
                let filter_values = (filter[0] as TransitFilter).value
                if (isNullOrUndefined(filter_values)) {
                    filter_values = value;
                } else {
                    if (!filter_values.includes(value[0])) {
                        let values_copy = [...filter_values];
                        values_copy.push(value[0]);
                        filter_values = values_copy;
                    }
                }
                (filter[0] as TransitFilter).value = filter_values;
            } else {
                alert('The filter selected is not an available option: ' + value); // 'add_special_filter'
            }
        });


        bus.$on('add_special_filter', (filterId: string, value: any, setAsOptions?: boolean) => {
            let filter = this.$refs[filterId + '_filter'] as any;

            if (setAsOptions) {
                (filter[0] as TransitFilter).options = [{ code: value[0], name: value[0] }];
            }
            (filter[0] as TransitFilter).value = value;

        });

    }


    destroyed() {
        bus.$off('append_name_filter');
        bus.$off('add_special_filter');
    };


    filter_assistant() {

        if (this.isFiltered) {

            var queryParams: any = {}
            for (var filter in this.filtersWithValues) {

                filter = this.filtersWithValues[filter]
                var filter_obj = (this.$refs[filter + '_filter'] as any)[0]
                var options = filter_obj.options

                var labels = options.filter(function (option: any) {
                    return filter_obj.value.includes(option.code)
                }).map(function (option: any) { return option.name });

                var unique_labels = labels.filter((element: any, index: number) => {
                    return labels.indexOf(element) === index;
                }).map(function (item: string) { return item.replace("_", " ") });

                queryParams[filter + '_filter'] = unique_labels
            }
            queryParams['mode_filter'] = this.densityType.toLowerCase()

            var question = this.create_filter_question(queryParams)

            bus.$emit('assistant', { content: question })

        }

    }


    create_filter_question(values_q: any) {

        //console.log(values_q)

        // Get the context for a question from the filter values
        var commodities: string[] = []
        if ("commodity_filter" in values_q) { commodities = commodities.concat(values_q["commodity_filter"]) }
        if ("commod_l2_filter" in values_q) { commodities = commodities.concat(values_q["commod_l2_filter"]) }
        if ("commod_l3_filter" in values_q) { commodities = commodities.concat(values_q["commod_l3_filter"]) }

        var link_name: string[] = []
        if ("roadname_filter" in values_q) { link_name = link_name.concat(values_q["roadname_filter"]) }
        if ("railname_filter" in values_q) { link_name = link_name.concat(values_q["railname_filter"]) }
        if ("sealane_filter" in values_q) { link_name = link_name.concat(values_q["sealane_filter"]) }
        if (link_name.length == 0) { link_name = [values_q["mode_filter"]] }

        var departing: string[] = []
        if ("origenterprise_filter" in values_q) { departing = departing.concat(values_q["origenterprise_filter"]) }
        if ("origenterprisecategory_filter" in values_q) { departing = departing.concat(values_q["origenterprisecategory_filter"]) }

        var arriving: string[] = []
        if ("destenterprise_filter" in values_q) { arriving = arriving.concat(values_q["destenterprise_filter"]) }
        if ("destenterprisecategory_filter" in values_q) { arriving = arriving.concat(values_q["destenterprisecategory_filter"]) }

        var boundary: string[] = []
        if ("boundary1_filter" in values_q) { boundary = boundary.concat(values_q["boundary1_filter"]) }
        if ("boundary2_filter" in values_q) { boundary = boundary.concat(values_q["boundary2_filter"]) }
        if ("boundary3_filter" in values_q) { boundary = boundary.concat(values_q["boundary3_filter"]) }
        if ("boundary4_filter" in values_q) { boundary = boundary.concat(values_q["boundary4_filter"]) }
        if ("boundary5_filter" in values_q) { boundary = boundary.concat(values_q["boundary5_filter"]) }
        if ("boundary6_filter" in values_q) { boundary = boundary.concat(values_q["boundary6_filter"]) }
        if ("boundary7_filter" in values_q) { boundary = boundary.concat(values_q["boundary7_filter"]) }
        if (boundary.length == 0) { boundary = ["Australia"] }

        // Build a question  for the assistant
        var question = "Please tell me about the supply chain movements"
        if (commodities.length > 0) {
            question = question + " of " + commodities.join(", ")
        }
        if (departing.length > 0) {
            question = question + " departing from " + departing.join(", ")
        }
        if (departing.length > 0 && arriving.length > 0) {
            question = question + " and"
        }
        if (arriving.length > 0) {
            question = question + " arriving at " + arriving.join(", ")
        }
        if (boundary.length > 0) {
            question = question + " in " + boundary.join(", ")
        }
        if (link_name.length > 0) {
            question = question + " via " + link_name.join(", ")
        }
        if ("month_filter" in values_q) {
            question = question + " for the month of " + values_q["month_filter"]
        }
        question = question + "?"

        return question

    }

    refreshMap() {
        if (this.hasUnconstrainedFilters && this.assistant.syncWithMap) {
            this.filter_assistant();
        }
        this.$emit('refresh_map')
    }

    // Triggered when a value is selected/removed in a filter, for example filterId = commodity, value = 126 (acid)
    onFiltersUpdated(filterId: string, value: any) {
        this.setFilterValue({ filter: filterId, value: value })
        this.setFiltersDirty()
        bus.$emit('filters_updated', filterId, value)
    }


    filterToggled(filterId: string, value: boolean, filter: any) {

        this.setFilterEnabled({ filter: filterId, value: value });

        if (!value && this.filterHasValue(filterId)) {
            this.onFiltersUpdated(filterId, [])
        }
    }


}



