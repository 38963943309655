export var paintStyles = {

    'trailer_loads': {
        property: 'trailer_loads',
        type: 'exponential',
        default: '#000000',
        // These need to be specified ranges otherwise it doesn't match legend and it also blends colors.
        stops: [
            [0, '#00F5F5'],
            [250, '#00F5F5'],
            [251, '#2DC8F7'],
            [500, '#2DC8F7'],
            [501, '#369AF7'],
            [1000, '#369AF7'],
            [1001, '#3674F7'],
            [2000, '#3674F7'],
            [2001, '#2F4DF7'],
            [5000, '#2F4DF7'],
            [5001, '#1B22F5'],
            [10000, '#1B22F5'],
            [10001, '#6200F5'],
            [20000, '#6200F5'],
            [20001, '#8700F5'],
            [50000, '#8700F5'],
            [50001, '#D000F5'],
            [100000, '#D000F5'],
            [100001, '#F500F5'],
        ],
    },
    'tonnes': {
        property: 'tonnes',
        type: 'exponential',
        default: '#000000',
        stops: [
            [0, '#65E0F9'],
            [250000, '#65E0F9'],
            [250001, '#369AF7'],
            [500000, '#369AF7'],
            [500001, '#3100F5'],
            [750000, '#3100F5'],
            [750001, '#AB00F5'],
            [1000000, '#AB00F5'],
            [1000001, '#F500F5'],
        ],
    },
    'tonnes_per_trailer': {
        property: 'tonnes_per_trailer',
        type: 'exponential',
        default: '#000000',
        stops: [
            [0, '#65E0F9'],
            [25, '#65E0F9'],
            [26, '#369AF7'],
            [50, '#369AF7'],
            [51, '#3100F5'],
            [100, '#3100F5'],
            [101, '#AB00F5'],
            [150, '#AB00F5'],
            [151, '#F500F5'],
        ],
    },
    'trip_transport_costs': {
        property: 'trip_transport_costs',
        //type: 'exponential',
        default: '#000000',
        //stops: [
        //    [0, '#65E0F9'],
        //    [25000000, '#369AF7'],
        //    [100000000, '#3100F5'],
        //    [250000000, '#AB00F5'],
        //    [500000000, '#F500F5'],
        //],
        // These need to be specified ranges otherwise it doesn't match legend and it also blends colors.
        stops: [
            [0, '#65E0F9'], //light blue
            [25000000, '#65E0F9'], //light blue
            [25000001, '#369AF7'], //darker blue
            [100000000, '#369AF7'], //darker blue
            [100000001, '#3100F5'], //darker darker blue
            [250000000, '#3100F5'], //darker darker blue
            [250000001, '#AB00F5'], //dark purple
            [500000000, '#AB00F5'], //dark purple
            [500000001, '#F500F5'], //purple
        ],
    },
    'trip_freight_value': {
        property: 'trip_freight_value',
        type: 'exponential',
        default: '#000000',
        stops: [
            [0, '#65E0F9'],
            [250000000, '#65E0F9'],
            [250000001, '#369AF7'],
            [500000000, '#369AF7'],
            [500000001, '#3100F5'],
            [1000000000, '#3100F5'],
            [1000000001, '#AB00F5'],
            [5000000000, '#AB00F5'],
            [5000000001, '#F500F5'],
        ],
    },
    'trip_avg_distance': {
        property: 'trip_avg_distance',
        type: 'exponential',
        default: '#000000',
        stops: [
            [0, '#65E0F9'],
            [200, '#65E0F9'],
            [201, '#369AF7'],
            [400, '#369AF7'],
            [401, '#3100F5'],
            [600, '#3100F5'],
            [601, '#AB00F5'],
            [800, '#AB00F5'],
            [801, '#F500F5'],
        ],
    },
    'trip_avg_duration': {
        property: 'trip_avg_duration',
        type: 'exponential',
        default: '#000000',
        stops: [
            [0, '#65E0F9'],
            [4, '#65E0F9'],
            [5, '#369AF7'],
            [8, '#369AF7'],
            [9, '#3100F5'],
            [12, '#3100F5'],
            [13, '#AB00F5'],
            [16, '#AB00F5'],
            [17, '#F500F5'],
        ],
    },
    'co2_tn': {
        property: 'co2_tn',
        type: 'exponential',
        default: '#000000',
        stops: [
            [0, '#65E0F9'],
            [50000, '#65E0F9'],
            [50001, '#369AF7'],
            [100000, '#369AF7'],
            [100001, '#3100F5'],
            [150000, '#3100F5'],
            [150001, '#AB00F5'],
            [200000, '#AB00F5'],
            [200001, '#F500F5'],
        ],
    },
    'truck': {
        property: 'truck',
        type: 'categorical',
        default: '#000000',
        stops: [
            ['ST', '#F0F'],
            ['BD', '#FF0'],
            ['T1', '#0F0'],
            ['T2', '#F00'],
            ['U', '#e9e8eb'],
        ]
    },
    'surface': {
        property: 'surface',
        type: 'categorical',
        default: '#000000',
        stops: [
            [true, '#31a354'], // Sealed
            [false, '#fb6a4a'] // Unsealed

        ]
    },
    'road_speed': {
        property: 'road_speed',
        type: 'categorical',
        default: '#000000',
        stops: [
            [5, '#0B486B'],
            [20, '#339FFF'],
            [40, '#2BC46A'],
            [60, '#FF0000'],
            [80, '#ff1aff'],
            [95, '#ff944d'],
            [110, 'yellow'],

        ],
    },
    'road_rank': {
        property: 'road_rank',
        type: 'categorical',
        default: '#000000',
        stops: [
            [1, '#cc3300'],
            [2, '#ff0080'],
            [3, '#ffb380'],
            [4, 'yellow'],
            [5, '#2ECC71'],
        ]
    },
    'rail_speed': {
        property: 'rail_speed',
        type: 'categorical',
        default: '#000000',
        stops: [
            [20, '#65E0F9'],
            [25, '#65E0F9'],
            [30, '#65E0F9'],
            [35, '#339FFF'],
            [40, '#2BC46A'],
            [50, '#FF0000'],
            [55, '#ff1aff'],
            [60, '#ff944d'],
            [65, '#cc3300'],
            [70, '#99ceff'],
            [75, '#3100F5'],
            [80, '#AB00F5']

        ],
    },
    'gauge': {
        property: 'gauge',
        type: 'categorical',
        default: '#000000',
        stops: [
            ['610', '#339FFF'],
            ['1067', '#F0F'],
            ['1435', '#FF0'],
            ['1600', '#0F0'],
            ['Dual', '#F00'],
        ]
    },
    //'trailer_loads_difference': {
    //    property: 'trailer_loads',
    //    //type: 'exponential',
    //    default: '#000000',
    //    stops: [
    //        [-100000, '#000000'],
    //        [-500, '#007206'],
    //        [-350, '#2A8E0A'],
    //        [-250, '#65AB14'],
    //        [-150, '#95C617'],
    //        [-51, '#C4E31D'],
    //        [-50, '#828282'],
    //        [0, '#828282'],
    //        [50, '#828282'],
    //        [51, '#F9DF1A'],
    //        [150, '#FDBD11'],
    //        [250, '#FF9A0B'],
    //        [350, '#FE710B'],
    //        [500, '#FC3B09'],
    //        [100000, '#000000'],
    //    ],
    //}

}
