






























































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { State, Action, Getter } from 'vuex-class';

//import { bus } from '@/pages/transitweb/main'

import { DensityType, DatasetState } from '@/store/datasets/types';

@Component({
    components: {
    }
})
export default class LinksReport extends Vue {

    @Prop() private reportConfig!: any;
    @Prop() private reportData!: any;
    @Prop() private isDownloadDisabled!: boolean;
    @Prop() private downloading_report!: boolean;
    @Prop() private isStandAlone!: boolean;
    @Prop() private statisticType!: string;

    @State('datasets') datasets!: DatasetState;

    DensityType: any = DensityType;

    get dataset(): string | undefined {
        return this.datasets.dataset;
    }


    get densityType(): DensityType {
        return this.datasets.type;
    }

    numberWithCommas(x: any): string {
        return Number(x).toLocaleString()
    }

}

