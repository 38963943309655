





















































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { State, Action, Getter } from 'vuex-class';
import { TaskStatus, TaskType } from './types'
import { bus } from '@/pages/transitweb/main'

import { endpoints } from "@/endpoints";

import { isNullOrUndefined } from 'util';

@Component({
    components: {
    }
})
export default class Task extends Vue {

    @Prop() private id!: string;
    @Prop() private execution_id!: string;
    @Prop() private task_type!: TaskType;
    @Prop() private task_name!: string;
    @Prop() private status!: TaskStatus;
    @Prop() private create_time!: Date;
    @Prop() private start_time!: Date;
    @Prop() private end_time!: Date;
    @Prop() private attempts!: number;
    @Prop() private inputs!: any;
    @Prop() private outputs!: any;

    @Getter('auth/isAllowedSensitiveInfo') isAllowedSensitiveInfo!: boolean;
    @Getter('auth/isAdmin') isAdmin!: boolean;

    TaskStatus: any = TaskStatus;
    
    get runtime() {
        var end = isNullOrUndefined(this.end_time) ? new Date() : this.end_time;
        var diffMs = end.getTime() - this.start_time.getTime();                     // milliseconds between start and end
        var diffDays = Math.floor(diffMs / 86400000);                               // days
        var diffHrs = Math.floor((diffMs % 86400000) / 3600000);                    // hours
        var diffMins = Math.floor(((diffMs % 86400000) % 3600000) / 60000);         // minutes
        var diffSecs = Math.floor((((diffMs % 86400000) % 3600000) % 60000) / 1000);// seconds

        var ret = ''
        if (diffDays > 0) {
            ret = ret + diffDays + " D "
        }
        if (diffHrs > 0) {
            ret = ret + diffHrs + " h "
        }
        if (diffMins > 0) {
            ret = ret + diffMins + " m"
        }
        if (diffSecs > 0 && diffMs < 1000 * 60 * 1) { // Only display seconds if the job is less than 1 mins.
            ret = ret +  " " + diffSecs + " s"
        }
        return ret
    }

    get status_color() {
        var colors = {
            [TaskStatus.Submitted]: 'white',
            [TaskStatus.Complete]: 'green lighten-5',
            [TaskStatus.Running]: 'blue lighten-5',
            [TaskStatus.Cancelled]: 'blue-grey lighten-5',
            [TaskStatus.Failed]: 'red lighten-5',
        }
        return (colors as any)[this.status];
    }

    get status_icon() {
        var icons = {
            [TaskStatus.Submitted]: 'run',
            [TaskStatus.Complete]: 'check',
            [TaskStatus.Running]: 'run',
            [TaskStatus.Cancelled]: 'block',
            [TaskStatus.Failed]: 'error_outline',
        }
        return (icons as any)[this.status];
    }


    // Cancel a task.
    onTaskCancelled(e: any) {
        console.log('Cancelling task ' + this.task_name)

        Vue.axios({
            url: endpoints.cancelTaskUrl(this.execution_id),
            method: 'DELETE'
        }).then((response: any) => {
            console.log(response);
            bus.$emit('refresh_tasks', true);
        }, (error: any) => {
            console.log(error);
        });
    }


    // Launch a task.
    onViewScenario(e: any) {
        if (this.task_type == TaskType.Scenario || this.task_type == TaskType.Scenario_Test) {
            console.log('Loading scenario for ' + this.task_name)
            
            var e: any = {
                task_name: this.task_name,
                inputs: this.inputs
            }

            bus.$emit('scenario_load', e)
            bus.$emit('task_list_dialog', false);
        }
    }

    // View the report.
    onViewReport(e: any) {

        Vue.axios({
            url: endpoints.viewScenarioReport(this.outputs.uri.split('/').pop())
        }).then((response: any) => {

            if (!isNullOrUndefined(response.data)) {
                var signed_url: any = response.data;

                if (signed_url == "None") {
                    //this.report_not_ready_alert = true
                    alert('Report is not ready')

                } else {
                    (window as any).open(signed_url, '_blank');
                }
            }

        }, (error: any) => {
            console.log(error);
            alert(error.response.data.detail)
        });
    }


    // View Logs.
    onViewTaskLogs(e: any) {
        (window as any).open(this.outputs.log_uri);
    }


}


