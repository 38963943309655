































































import { Component, Prop, Vue } from 'vue-property-decorator';
import { State, Action, Getter } from 'vuex-class';
import { endpoints } from '../../endpoints'

import markdownit from 'markdown-it';

@Component
export default class Terms extends Vue {

    terms: any = {};
    valid: boolean = false;
    terms_read: boolean = false
    loading = false;

    error_message: string = ''
    get error() {
        return this.error_message !== ''
    }

    get getFormattedVersion(): string {
        let version = this.terms.version_number
        return (Math.round(version * 100) / 100).toFixed(2);
    }

    get getFormattedDate(): string {
        const options = { weekday: 'short', year: 'numeric', month: 'long', day: 'numeric' };
        return new Date(this.terms.date_active).toLocaleDateString("en-AU", options);
    }

    created() {

        this.loading = true;

        Vue.axios({
            url: endpoints.termsUrl
        }).then((response: any) => {
            this.loading = false;
            this.terms = response.data[0] // This assumes there's on 1 set of terms for now.
            if (!this.terms) {
                this.$router.push("/");   // Just go back to the root if there a no terms to sign.
            }
            Vue.nextTick(() => {
                this.terms_read = !this.checkOverflow(this.$refs.terms);
            });
        }, (error: any) => {
            this.loading = false;
            console.log(error)
        });

    }

    getMarkdown(content: string) {
        if (content) {
            var result = markdownit().render(content.trim());
        }
        return result;
    }

    // Determines if the passed element is overflowing its bounds,
    // either vertically or horizontally.
    // Will temporarily modify the "overflow" style to detect this
    // if necessary.
    checkOverflow(el: any) {
        var curOverflow = el.style.overflow;

        if (!curOverflow || curOverflow === "visible")
            el.style.overflow = "hidden";

        var isOverflowing = el.clientWidth < el.scrollWidth
            || el.clientHeight < el.scrollHeight;

        el.style.overflow = curOverflow;

        return isOverflowing;
    }

    onScroll(event: any) {
        if (event.target.offsetHeight + event.target.scrollTop >= event.target.scrollHeight) {
            console.log("End of Terms");
            this.terms_read = true;
        }
    }

    onAcceptTerms() {

        this.loading = true;

        Vue.axios({
            url: endpoints.termsUrl, method: "POST", data: { terms: [this.terms.id] }
        }).then((response: any) => {
            this.loading = false;
            this.$router.push("/");
        }, (error: any) => {
            this.loading = false;
            console.log(error)
            this.error_message = error.response.data.detail
        });
    }
}

