





























import { Component, Prop, Vue, Watch, } from 'vue-property-decorator';
import { bus } from '@/pages/transitweb/main'

import buildUrl from 'build-url';
import { endpoints } from '../../endpoints'
import markdownit from 'markdown-it';

@Component
export default class TermsViewer extends Vue {

    @Prop() private visible!: boolean;
    @Prop() private slug!: string;

    isVisible: boolean = false;
    terms: any = {};

    @Watch('visible')
    onVisibleChange(newVal: boolean) {
        this.isVisible = newVal;

        if (newVal) {
            Vue.axios({
                //url: buildUrl(endpoints.termsUrl, { queryParams: { "slug": this.slug } })
                url: endpoints.termsUrl + "?slug=" + this.slug
            }).then((response: any) => {
                this.terms = response.data[0] // This assumes there's on 1 set of terms for now.
            }, (error: any) => {
                console.log(error)
            });
        } else{
            this.terms = {};
        }
    }

    get getFormattedVersion(): string {
        let version = this.terms.version_number
        return (Math.round(version * 100) / 100).toFixed(2);
    }

    get getFormattedDate(): string {
        const options = { weekday: 'short', year: 'numeric', month: 'long', day: 'numeric' };
        return new Date(this.terms.date_active).toLocaleDateString("en-AU", options);
    }

    getMarkdown(content: string) {
        if (content) {
            var result = markdownit().render(content.trim());
        }
        return result;
    }

    close_terms_dialog() {
        this.isVisible = false;
        bus.$emit('close_terms_dialog', false);
    }

}

