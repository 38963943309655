import Vue from 'vue';
import Router, { Route } from 'vue-router';

import SignIn from '@/components/auth/SignIn.vue';
import Register from '@/components/auth/Register.vue';
import ForgotPassword from '@/components/auth/ForgotPassword.vue';
import VerifyRegistration from '@/components/auth/VerifyRegistration.vue';
import ResetPassword from '@/components/auth/ResetPassword.vue';
import Account from '@/components/auth/Account.vue';
import MFA from '@/components/auth/MFA.vue';
import Terms from '@/components/auth/Terms.vue';
import TransitMap from '@/components/TransitMap.vue';
import Assistant from '@/components/assistant/Assistant.vue';
import Reports from '@/components/reports/Reports.vue';
import SupplyChainExplorer from '@/components/sce/SupplyChainExplorer.vue';
//import Benchmark from '@/components/benchmark/BenchmarkPanel.vue';
//import Benchmark from '@/components/BenchmarkWrapper.vue';
const Benchmark = () => import('@/components/BenchmarkWrapper.vue');
Vue.use(Router);

import store from '../store/index'


var router = new Router({
    mode: 'history',
    routes: [
        {
            path: '/assistant',
            name: 'Assistant',
            component: Assistant,
            meta: {
                requiresAuth: !store.getters['auth/isAnonymousMode'],
                title: store.getters['assistant/assistantName']
            },
            props: { assistant_open: true, isStandAlone: true }
        },
        {
            path: '/reports',
            name: 'Reports',
            component: Reports,
            meta: {
                requiresAuth: !store.getters['auth/isAnonymousMode'],
                title: 'TraNSIT Web Reports'
            },
            props: {
                queryParamsCallback: () => {
                    return store.getters['tables/queryParams'];
                },
                isStandAlone: true
            }
        },
        {
            path: '/',
            name: 'Map',
            component: TransitMap,
            meta: {
                requiresAuth: !store.getters['auth/isAnonymousMode']
            }
        },
        {
            path: '/explorer',
            name: 'Explorer',
            component: SupplyChainExplorer,
            meta: {
                requiresAuth: !store.getters['auth/isAnonymousMode']
            }
        },
        {
            path: '/benchmark',
            name: 'Benchmark',
            component: Benchmark,
            meta: {
                requiresAuth: !store.getters['auth/isAnonymousMode']
            }
        },
        {
            path: '/MFA',
            name: 'MFA',
            component: MFA,
            meta: {
                requiresAuth: false // Needs to be set to false because the user has identified themselves.
            },
            props: true
        },
        {
            path: '/signin',
            name: 'SignIn',
            component: SignIn
        },
        {
            path: '/register',
            name: 'Register',
            component: Register
        },
        {
            path: '/forgotpassword',
            name: 'Forgot Password',
            component: ForgotPassword
        },
        {
            path: '/verifyregistration',
            name: 'Verify Registration',
            component: VerifyRegistration
        },
        {
            path: '/resetpassword',
            name: 'Reset Password',
            component: ResetPassword
        },
        {
            path: '/account',
            name: 'Account',
            component: Account,
            meta: {
                requiresAuth: !store.getters['auth/isAnonymousMode']
            }
        },
        {
            path: '/terms',
            name: 'Terms',
            component: Terms,
            meta: {
                requiresAuth: !store.getters['auth/isAnonymousMode']
            }
        },
    ]
});


// References for Vuex Authentication best practices
// https://scotch.io/tutorials/handling-authentication-in-vue-using-vuex
// https://blog.sqreen.com/authentication-best-practices-vue/


router.beforeEach(async (to: any, from: any, next: any) => {

    await (store as any).restored; // Since indexedDB requests are asynchronous, need to wait.

    // If unknown route send them to the root (which will in turn send them to the signin screen if they are not authenticated.
    if (to.matched.length === 0) {
        next('/');
        return; // Can only call a navigation guard once in a pass through! So return, as this is our 1 call used.
    }
    // If the route requires auth and they are not logged in, send them back to the sign-in screen.
    if (to.meta.requiresAuth) {
        if (store.getters['auth/isAuthenticated']) {
            if (store.getters['auth/isMfaRequired'] && !store.getters['auth/isMfaActivated']) {
                next('/MFA');   // Go to MFA screen if it is required but the user does not have it activated.
            } else {
                next();         // Auth required and user is authed, so go there.
            }
        } else {
            next({ path: '/signin', query: { redirect: to.fullPath } });    // Auth required but not authed, so go to login.
        }
    } else {
        next();                 // No auth required, so go there.
    }
});

router.afterEach((to: any, from: any) => {

    // Use next tick to handle router history correctly
    // see: https://github.com/vuejs/vue-router/issues/914#issuecomment-384477609
    Vue.nextTick(() => {
        document.title = to.meta.title || "TraNSIT Web"; // Adjust title of web page if specified in meta (i.e Herbie in standalone mode)
    });

});


export default router
