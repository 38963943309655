var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{staticClass:"elevation-1 table",attrs:{"headers":_vm.densityType === _vm.DensityType.Road || _vm.densityType === _vm.DensityType.Road_Events_Baseline || _vm.densityType === _vm.DensityType.Road_Events_Scenario ? _vm.reportConfig.headers.roads[_vm.statisticType] : _vm.densityType === _vm.DensityType.Rail ? _vm.reportConfig.headers.rail[_vm.statisticType] : _vm.reportConfig.headers.sea[_vm.statisticType],"items":_vm.reportData,"sort-by":_vm.reportConfig.pagination.sortBy,"sort-desc":_vm.reportConfig.pagination.descending,"search":_vm.reportConfig.search,"item-key":"commodity","show-group-by":"","dense":"","footer-props":{ 'items-per-page-options': [-1,40,30,20,10,5] },"items-per-page":_vm.isStandAlone ? -1 : 5},on:{"update:sortBy":function($event){return _vm.$set(_vm.reportConfig.pagination, "sortBy", $event)},"update:sort-by":function($event){return _vm.$set(_vm.reportConfig.pagination, "sortBy", $event)},"update:sortDesc":function($event){return _vm.$set(_vm.reportConfig.pagination, "descending", $event)},"update:sort-desc":function($event){return _vm.$set(_vm.reportConfig.pagination, "descending", $event)}},scopedSlots:_vm._u([{key:"group.header",fn:function(ref){
var group = ref.group;
var headers = ref.headers;
var toggle = ref.toggle;
var isOpen = ref.isOpen;
var items = ref.items;
var groupBy = ref.groupBy;
var remove = ref.remove;
return [_c('td',{staticStyle:{"background":"#e6f2ff","border-top":"solid","color":"#1976d2"},attrs:{"colspan":headers.length}},[_c('v-btn',{ref:group,attrs:{"x-small":"","icon":""},on:{"click":toggle}},[(isOpen)?_c('v-icon',{staticStyle:{"color":"#1976d2"},attrs:{"title":"Collapse this group"}},[_vm._v("mdi-arrow-up-drop-circle")]):_c('v-icon',{staticStyle:{"color":"#1976d2"},attrs:{"title":"Expand this group"}},[_vm._v("mdi-arrow-down-drop-circle")])],1),_c('span',{staticClass:"mx-2 font-weight-bold"},[_vm._v(_vm._s(group))]),_c('v-btn',{attrs:{"x-small":"","icon":""},on:{"click":remove}},[_c('v-icon',{attrs:{"title":"Ungroup all rows"}},[_vm._v("mdi-window-close")])],1),_c('span',{staticClass:"font-weight-bold",staticStyle:{"float":"right"}},[_vm._v("$"+_vm._s(_vm.numberWithCommas(_vm.total_group(groupBy, group, 'trip_transport_costs'))))])],1)]}},_vm._l((_vm.densityType === _vm.DensityType.Road || _vm.densityType === _vm.DensityType.Road_Events_Baseline || _vm.densityType === _vm.DensityType.Road_Events_Scenario ? _vm.reportConfig.headers.roads[_vm.statisticType] : _vm.densityType === _vm.DensityType.Rail ? _vm.reportConfig.headers.rail[_vm.statisticType] : _vm.reportConfig.headers.sea[_vm.statisticType]),function(header){return {key:("header." + (header.value)),fn:function(ref){
var header = ref.header;
return [_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('span',{attrs:{"title":header.description}},[_vm._v(" "+_vm._s(header.text)+" ")])]}}],null,true)})]}}}),{key:"footer",fn:function(){return [_c('v-row',{attrs:{"no-gutters":""}},[_c('v-btn',{staticClass:"mt-3 ml-3",attrs:{"slot":"actions-append","loading":_vm.downloading_report,"disabled":_vm.isDownloadDisabled,"rounded":"","outlined":"","color":"primary","title":"Download this report as a CSV"},on:{"click":function($event){return _vm.$emit('download_report', 'Commodities')}},slot:"actions-append"},[_c('span',[_vm._v("Download Report")]),_c('span',{staticClass:"icon-spacer"}),_c('font-awesome-icon',{attrs:{"icon":"download"}})],1),_c('v-spacer'),_c('v-text-field',{staticClass:"avoid-pagination",attrs:{"slot":"actions-prepend","append-icon":"search","label":"Search commodity summary","single-line":"","hide-details":""},slot:"actions-prepend",model:{value:(_vm.reportConfig.search),callback:function ($$v) {_vm.$set(_vm.reportConfig, "search", $$v)},expression:"reportConfig.search"}}),_c('v-spacer')],1)]},proxy:true},{key:"item.trailer_loads",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.numberWithCommas(item.trailer_loads))+" ")]}},{key:"item.tonnes",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.numberWithCommas(item.tonnes))+" ")]}},{key:"item.tonnes_per_trailer",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.numberWithCommas(item.tonnes_per_trailer))+" ")]}},(_vm.statisticType != 'Monthly')?{key:"item.avg_trip_distance",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.numberWithCommas(Math.round(item.avg_trip_distance * 10) / 10))+" ")]}}:null,(_vm.statisticType != 'Monthly')?{key:"item.avg_trip_duration",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(Math.round(item.avg_trip_duration * 100) / 100.0)+" ")]}}:null,(_vm.statisticType != 'Monthly')?{key:"item.cost_per_tonne",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(Math.round(item.trip_transport_costs / item.tonnes * 100) / 100)+" ")]}}:null,(_vm.statisticType != 'Monthly')?{key:"item.cost_per_tonne_km",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.numberWithCommas(item.cst_per_tonne_km))+" ")]}}:null,(_vm.statisticType != 'Monthly')?{key:"item.tonne_kms_links",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.numberWithCommas(item.tonne_kms_links))+" ")]}}:null,(_vm.statisticType != 'Monthly')?{key:"item.avg_links_kms",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(Math.round(item.avg_links_kms * 10) / 10)+" ")]}}:null,(_vm.statisticType != 'Monthly')?{key:"item.total_freight_value",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.numberWithCommas(item.total_freight_value))+" ")]}}:null,(_vm.statisticType != 'Monthly')?{key:"item.trip_transport_costs",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.numberWithCommas(item.trip_transport_costs))+" ")]}}:null],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }