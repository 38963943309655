

















































































































































































































































































































































































































import { Component, Prop, Vue, Watch, Inject } from 'vue-property-decorator';
import { State, Action, Getter } from 'vuex-class';

import { DatasetState } from '@/store/datasets/types';
import { EventsState } from '@/store/events/types';
import { DensityType } from '@/store/datasets/types';

import { bus } from '@/pages/transitweb/main'

import { Scenario, ScenarioAction, Baseline, StrategyType, ActionType, FeatureType, PolygonOperator } from './types'
import { TaskType } from '../tasks/types'

import { endpoints } from "@/endpoints";

import { isNullOrUndefined } from 'util';

import { mixins } from 'vue-class-component';
import PolygonColors from '../scenarios/polygon-colors-mixin'

import ActionEditor from './ActionEditor.vue';
import PreviewMap from './PreviewMap.vue';

import * as MapboxDraw from '@mapbox/mapbox-gl-draw';
import "@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css";

import * as polygon_styles from '../layers/polygon_styles';

var geojsonExtent = require('@mapbox/geojson-extent')

@Component({
    components: {
        ActionEditor,
        PreviewMap,
        MapboxDraw
    },
})

export default class ScenarioStudio extends mixins(PolygonColors) {

    @State('datasets') datasets!: DatasetState;
    @State('events') events!: EventsState;

    @Getter('auth/username') username!: string;
    @Getter('auth/isAdmin') isAdmin?: boolean;
    @Getter('auth/isAllowedSensitiveInfo') isAllowedSensitiveInfo!: boolean;

    @Action('events/getEventDateItems') getEventDateItems: any;

    @Prop() private getMap!: any;

    DensityType: any = DensityType;
    ActionType: any = ActionType;
    StrategyType: any = StrategyType;
    FeatureType: any = FeatureType;

    task_name: string = '';
    scenario_actions: ScenarioAction[] = [];
    valid: boolean = true;
    rules: any = {};
    confirm_dialog: boolean = false;

    event_date = '';

    // Used to create mapbox-draw instance
    draw: any = null;

    loading_map: boolean = false;
    loading_create_task: boolean = false;

    creating_action: StrategyType | null = null; // Holds a strategy type of an action to create

    error_msg: string = "";

    editing_index: number = -1;
    editing_action: any = {};

    baseline: string = Baseline.SHORT_TERM_FORECAST;
    baseline_items: any[] =
        [
            { text: '7-Day Forecast', value: Baseline.SHORT_TERM_FORECAST, icon: './7days2.png', description: 'Produce a forecast for the next week, starting at todays date' },
            { text: 'Annual Baseline', value: Baseline.ANNUAL, disabled: true, icon: './365days2.png', description: 'Produce a forecast against the annual baseline' }
        ]

    polygon_operator = PolygonOperator.UNION
    polygon_operator_items: any[] =
        [
            {
                text: 'Union',
                value: 'union',
                description: 'Polygons are unioned (' + "&#8746;" + ')  with other scenario actions',
                icon: './union.svg'
            },
            {
                text: 'Intersection',
                value: 'intersection',
                description: 'Polygons are intersected (' + "&#8745;" + ') with other scenario actions',
                icon: './intersection.svg'
            },
        ]

    baseline_items_admin: any[] =
        [
            { divider: true },
            { header: 'Admin Reporting' },
            { text: '7DF for Jan', value: '0', icon: './7days2.png', description: 'Produce a 7-day forecast, starting at todays date in January' },
            { text: '7DF for Feb', value: '1', icon: './7days2.png', description: 'Produce a 7-day forecast, starting at todays date in February' },
            { text: '7DF for Mar', value: '2', icon: './7days2.png', description: 'Produce a 7-day forecast, starting at todays date in March' },
            { text: '7DF for Apr', value: '3', icon: './7days2.png', description: 'Produce a 7-day forecast, starting at todays date in April' },
            { text: '7DF for May', value: '4', icon: './7days2.png', description: 'Produce a 7-day forecast, starting at todays date in May' },
            { text: '7DF for Jun', value: '5', icon: './7days2.png', description: 'Produce a 7-day forecast, starting at todays date in June' },
            { text: '7DF for Jul', value: '6', icon: './7days2.png', description: 'Produce a 7-day forecast, starting at todays date in July' },
            { text: '7DF for Aug', value: '7', icon: './7days2.png', description: 'Produce a 7-day forecast, starting at todays date in August' },
            { text: '7DF for Sep', value: '8', icon: './7days2.png', description: 'Produce a 7-day forecast, starting at todays date in September' },
            { text: '7DF for Oct', value: '9', icon: './7days2.png', description: 'Produce a 7-day forecast, starting at todays date in October' },
            { text: '7DF for Nov', value: '10', icon: './7days2.png', description: 'Produce a 7-day forecast, starting at todays date in November' },
            { text: '7DF for Dec', value: '11', icon: './7days2.png', description: 'Produce a 7-day forecast, starting at todays date in December' },
        ];

    create_action_buttons: any = [
        {
            title: "Add action by drawing a polygon on the main map",
            strategy_type: StrategyType.AREA,
            icon: "mdi-shape-polygon-plus",
        },
        {
            title: "Add action by selecting a segment on the main map",
            strategy_type: StrategyType.FEATURE_ID,
            icon: "mdi-link-plus",
        },
        {
            title: "Add action by selecting a road on the main map",
            strategy_type: StrategyType.ROAD,
            icon: "mdi-road-variant",
        },
    ]

    headers: any = [
        { text: 'Action', align: 'center', value: 'action', sortable: false, width: 140, inDevelopment: false, },
        { text: 'Value', align: 'center', value: 'description', sortable: false, width: 300, inDevelopment: false, },
        { text: 'Edit', align: 'center', value: 'edit', sortable: false, width: 50, inDevelopment: true, },
        { text: 'Up', align: 'center', value: 'moveUp', sortable: false, inDevelopment: true, },
        { text: 'Down', align: 'center', value: 'moveDown', sortable: false, inDevelopment: true, },
        { text: 'Remove', align: 'center', value: 'remove', sortable: false, inDevelopment: false, },
    ];

    get dataset(): any {
        return this.datasets.dataset;
    }

    get densityType(): DensityType {
        return this.datasets.type;
    }

    // Add in all months of the year, so admins can test that forecasts work all year round.
    get all_baseline_items() {
        return !this.isAdmin ? this.baseline_items : this.baseline_items.concat(this.baseline_items_admin);
    }

    // Gets the baseline parameter needed to run a scenario.
    get getBaseline() {
        var month = parseInt(this.baseline);
        return isNaN(month) ? this.baseline : Baseline.SHORT_TERM_FORECAST;
    }

    // Gets the forecasted date to use on a scenario.
    get getForecastDate() {

        var date = new Date();
        if (this.baseline == Baseline.SHORT_TERM_FORECAST) {
            return date;
        } else if (this.baseline == Baseline.ANNUAL) {
            return undefined;
        }
        else {
            // Adjust the month.
            var month = parseInt(this.baseline);
            date.setMonth(month);
            return date;
        }
    }


    //-------------------------------------------
    //---------      Initialisation    ----------
    //-------------------------------------------


    created() {

        this.initializeDraw(); // Initialize draw.

        // Gets the dates for which closed road layers are available.
        this.getEventDateItems(this.dataset);

        bus.$on('scenario_close_segment', (e: any, feature_type: FeatureType) => {

            var action = Object.assign({}, e);
            action['action_type'] = ActionType.CLOSE;
            action['feature_type'] = feature_type;
            action['strategy_type'] = StrategyType.FEATURE_ID;
            action['description'] = e.link_id + ' (on ' + e.street_name + ')';
            action['value'] = e.link_id;

            this.addAction(action);
            bus.$emit('scenario_studio', true);
        })

        bus.$on('scenario_close_road', (e: any, feature_type: FeatureType) => {

            var action = Object.assign({}, e);
            action['action_type'] = ActionType.CLOSE;
            action['feature_type'] = feature_type;
            action['strategy_type'] = StrategyType.ROAD;
            action['description'] = e.street_name;
            action['value'] = e.street_name;

            this.addAction(action);
            bus.$emit('scenario_studio', true);
        })

        bus.$on('scenario_closed_roads', (e: any, feature_type: FeatureType) => {

            var action = Object.assign({}, e);
            action['action_type'] = ActionType.CLOSE;
            action['feature_type'] = feature_type;
            action['strategy_type'] = StrategyType.CLOSED_ROADS;
            action['description'] = e.event_date;
            action['value'] = e.event_date;

            this.addAction(action);
            this.event_date = e.event_date;
            bus.$emit('scenario_studio', true);
        })

        bus.$on('scenario_load', (e: any) => {

            this.clearScenario();
            this.task_name = e.task_name;
            this.baseline = e.inputs.baseline;
            this.polygon_operator = e.inputs.polygon_operator

            for (var action of e.inputs.actions.reverse()) {
                this.addAction(action);
            }
            bus.$emit('scenario_studio', true);
        })
    };

    // Event handlers seem to need to be turned off on destroyed... Otherwise if you logout then login 2 (or more depending on how many times you logout) event handlers will be present?
    destroyed() {

        bus.$off('scenario_close_segment');
        bus.$off('scenario_close_road');
        bus.$off('scenario_closed_roads');
        bus.$off('scenario_load');
        this.getMap.removeControl(this.draw);
    };

    // Return a formatted date for use in the closed roads date selector.
    get getFormattedDate(): string {
        const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
        return new Date(this.event_date).toLocaleDateString("en-AU", options);
    }

    // Checks if a date should be enabled/disabled in the closed roads date selector.
    allowedDates(val: string): boolean {
        return this.events.event_date_items.includes(val);
    }

    // Event Handler for changing the "closed roads" date.
    onDateChange(e: any) {

        // Find the existing closed roads action (if one exists).
        var closed_roads = this.scenario_actions.find((action: ScenarioAction) => action.strategy_type == StrategyType.CLOSED_ROADS) as ScenarioAction;

        if (e) {
            // Update the action value/description if the action if there is already a closed roads action.
            if (closed_roads) {
                Vue.set(closed_roads, 'value', e);
                Vue.set(closed_roads, 'description', e);
            }
            // Add an action for the new closed roads date.
            else {
                this.addAction({
                    action_type: ActionType.CLOSE,
                    strategy_type: StrategyType.CLOSED_ROADS,
                    feature_type: FeatureType.ROAD,
                    description: e,
                    value: e,
                });
            }
            // Delete the action.
        } else {
            this.onDeleteAction(closed_roads);
        }
    }


    //-------------------------------------------
    //---------     Scenario Actions   ----------
    //-------------------------------------------


    // The scenario definition.
    get scenario() {

        var scenario: Scenario = {
            actions: [],
            baseline: this.getBaseline,
            date: this.getForecastDate,
            polygon_operator: this.polygon_operator
        };

        for (const action of this.scenario_actions) {
            scenario.actions.push({
                action_type: action.action_type,
                feature_type: action.feature_type,
                strategy_type: action.strategy_type,
                description: action.description,
                value: action.value,
                parameters: action.parameters
            });
        }
        return scenario;
    }

    // A watcher for making adjustments when the scenario is updated.
    @Watch('scenario')
    onScenarioChanged(val: Scenario, oldVal: Scenario) {

        // Set task name automatically when going from no actions to 1 action.
        if (val.actions.length == 1 && (oldVal.actions.length <= 1 || this.task_name == '')) {
            this.task_name = this.actionDescription(val.actions[0], true)
        }

        // Clear the task name if there are no actions. Also, clear task name if going from 1 to 2 actions.
        if ((val.actions.length == 0) || (oldVal.actions.length == 1 && val.actions.length == 2)) {
            this.task_name = '';
        }
    }

    @Watch('densityType')
    onDensityTypeChanged(val: DensityType, oldVal: DensityType) {

        if (val == DensityType.Rail || val == DensityType.Sea) {
            this.disableDraw();
            this.draw.deleteAll();
        } else {
            this.scenario_actions.forEach((action: ScenarioAction) => {
                if (action.feature_type == FeatureType.ROAD && action.strategy_type === StrategyType.AREA) {
                    this.draw.add(action.value);
                }
            });
        }
    }

    // A watcher to disable mapbox draw when Add Feature Id or Add Road button was clicked after Add Polygon button.
    @Watch('creating_action')
    onActionToCreateChanged(val: StrategyType, oldVal: StrategyType) {
        if (val === StrategyType.AREA) {
            this.enableDraw();
        }
        if (oldVal === StrategyType.AREA) {
            this.disableDraw();
        }
        this.$emit('creating_action_changed', val === null)
    }

    // Adds a new action to the scenario.
    addAction(action: ScenarioAction) {

        // Clear error mesage.
        this.error_msg = "";
        var existingAction = this.scenario_actions.find((existing_action: ScenarioAction) => existing_action['value'] === action['value']);

        // If no duplicates detected, add the new action.
        if (!existingAction) {

            this.scenario_actions.unshift(action);

            // If the strategy was area, also draw the polygon value on the map.
            if (action.strategy_type === StrategyType.AREA) {
                this.draw.add(action.value);  // Draw the action polygon.
            }

        } else {
            // Show duplicate error message.
            this.error_msg = "The action selected already exists in this scenario."
        }
    };

    // Event Handler for moving an action up/down in its rank by the number of positions specified by the delta.
    onMoveAction(action: ScenarioAction, delta: number) {

        var index = this.scenario_actions.indexOf(action);
        var newIndex = index + delta;
        if (newIndex < 0 || newIndex == this.scenario_actions.length) return; // Already at the top or bottom.
        Vue.set(this.scenario_actions, index, this.scenario_actions[newIndex]);
        Vue.set(this.scenario_actions, newIndex, action);
    };

    // Event Handler for editing an action.
    onEditAction(action: ScenarioAction) {

        this.editing_index = this.scenario_actions.indexOf(action);
        this.editing_action = action
    }

    // Event Handler for updating an action using its index position.
    onUpdateAction(action: ScenarioAction, index: number) {
        Vue.set(this.scenario_actions, index, action);
    }

    // Event Handler for deleting an action from the scenario.
    onDeleteAction(action: ScenarioAction) {

        // Delete the action.
        var actionIndex = this.scenario_actions.indexOf(action);
        Vue.delete(this.scenario_actions, actionIndex);

        // Clear the event date if it was a Closed Roads Strategy.
        if (action.strategy_type == StrategyType.CLOSED_ROADS) {
            this.event_date = '';
        }

        // Remove the polygon from Mapbox if it was an Area Strategy.
        if (action.strategy_type == StrategyType.AREA) {
            this.draw.delete(action.value.id);
        }

        this.error_msg = "";

    };

    // Event Handler for flying to an action and also highlight it.
    async onFlyToAction(action: ScenarioAction) {
        var scenarioSingleAction = this.getSingleAction(action)
        var bounds = await (this.$refs.preview_map as PreviewMap).getScenarioBounds(scenarioSingleAction)
        bus.$emit('map_bounds', geojsonExtent(bounds));
        bus.$emit('highlight_action', scenarioSingleAction);
    }

    // Event Handler for highlighting an action.
    async onHighlightAction(action: ScenarioAction) {
        var scenarioSingleAction = this.getSingleAction(action)
        bus.$emit('highlight_action', scenarioSingleAction);
    }

    getSingleAction(action: ScenarioAction) {
        var index = this.scenario_actions.indexOf(action);
        var scenarioSingleAction = {
            ...this.scenario,
            actions: [this.scenario.actions[index]],
        };
        return scenarioSingleAction
    }

    // Clears the scenario.
    clearScenario() {

        this.task_name = '';
        this.scenario_actions = [];
        this.event_date = '';
        this.rules = {};
        this.polygon_operator = PolygonOperator.UNION; // Defaults polygon operator to 'union' after scenario is submitted.
        this.draw.deleteAll();
    }

    // Checks if the scenario contains an action for the provided strategy type.
    containsStrategyType(strategy_type: StrategyType) {
        return this.scenario_actions.some(
            (action: ScenarioAction) => action.strategy_type === strategy_type
        );
    }

    // Checks if the scenario contains an action for the provided action type.
    containsActionType(action_type: ActionType) {
        return this.scenario_actions.some(
            (action: ScenarioAction) => action.action_type === action_type);
    }

    // Get a name for the data table Action column.
    actionDescription(action: ScenarioAction, long_description = false) {

        // If it's a feature ID strategy for anything but an enterprise call it a segment.
        let strategy_type = action.strategy_type === StrategyType.FEATURE_ID && action.feature_type !== FeatureType.ENTERPRISE ? "Segment" : action.strategy_type 

        // If it's a GeoJSON strategy, then call it by it's feature type.
        strategy_type = action.strategy_type === StrategyType.GEOJSON ? action.feature_type : strategy_type;

        let description = action.strategy_type !== StrategyType.CLOSED_ROADS || long_description ? action.action_type + " " + strategy_type : strategy_type;

        // Add in the action description if it's a long description.
        description = action.strategy_type == StrategyType.CLOSED_ROADS && long_description ? description + " on" : description;
        return long_description ? description + " " + action.description : description
    }


    //-------------------------------------------
    //--------- Scenario Task Submission --------
    //-------------------------------------------


    // Event Handler for submitting a scenario to the task server for execution - because it is currently a long running process.
    onSubmitTask() {

        this.confirm_dialog = false;

        // Check the task name validation rules.
        this.rules = {
            task_name_rules: [
                function (v: any) { return !!v || "Please enter your task name" }, // Checks if the task name is not empty.
            ]
        }

        // Needed for validation of task name to work.
        this.$nextTick(() => {

            if ((this.$refs.form as Vue & { validate: () => boolean }).validate()) {

                this.loading_create_task = true;
                Vue.axios({
                    url: endpoints.createTaskUrl(this.dataset),
                    data: {
                        task_type: TaskType.Scenario,
                        //task_type: TaskType.Scenario_Test, // This is to run a test container.
                        task_name: this.task_name,
                        inputs: this.scenario
                    },
                    method: 'POST'
                }).then((response: any) => {
                    this.loading_create_task = false;
                    this.clearScenario();
                    console.log('Task Submitted!')
                    bus.$emit('task_submitted', response.data);
                    this.onClose()
                }, (error: any) => {
                    this.loading_create_task = false;
                    if (!isNullOrUndefined(error.response.data.detail)) {
                        this.error_msg = error.response.data.detail;
                    } else {
                        this.error_msg = "An error occurred submitting this task."
                    }
                    console.log(error);
                })
            }
        })
    }

    // Event Handler for checking if the task name has any invalid characters on the input whilst typing.
    onValidateTaskName(task_name: any) {

        this.rules = {
            task_name_rules: [
                function () {
                    if (/[/%\\#?]/.test(task_name)) {
                        return "Characters /, \\, ?, # and % are not allowed in task name"
                    } else {
                        return !/[/%\\#?]/.test(task_name)
                    }
                },
            ]
        }
    }

    // Event Handler for when the learn more button is clicked.
    onLearnMore() {
        (window as any).open('./docs/user_guide_scenarios.pdf');
    }


    //-------------------------------------------
    //---------  Draw a polygon on map ----------
    //-------------------------------------------

    // Initialize Mapbox Draw and polygon drawing controls.
    initializeDraw() {

        if (!this.draw) {
           
            // If Mapbox Draw doesn't exist, create new draw instance.
            this.draw = new MapboxDraw({
                userProperties: true, // Needed to be able to set color.
                displayControlsDefault: false,
                defaultMode: 'simple_select',
                styles: polygon_styles.polygonStyles,
                controls: {
                    polygon: false,
                    trash: false,
                },
            });

            this.getMap.addControl(this.draw, 'top-left');

            // Event handler for drawing features on the map.
            this.getMap.on('draw.create', (e: any) => {

                // Add a new action with an Area Strategy Type for each polygon feature that was drawn.
                e.features.forEach((feature: any) => {

                    if (feature.geometry.type == 'Polygon'){

                        // Assign the color to the new polygon.
                        feature.properties.color = this.getPolygonColor();

                        var action = Object.assign({}, feature);
                        action['action_type'] = ActionType.CLOSE;
                        action['feature_type'] = this.densityType; // This probably works fine for most feature types except enterprises (some day?)
                        action['strategy_type'] = StrategyType.AREA;
                        action['description'] = feature.geometry.type;
                        action['value'] = feature;  // Set the value as the GeoJSON object.

                        this.addAction(action);
                    }

                });
                this.creating_action = null;
            });

            // Event handler for updating features on the map.
            this.getMap.on('draw.update', (e: any) => {

                // Update each action which had it's feature geometry altered.
                e.features.forEach((feature: any) => {
                    var action = this.scenario_actions.find((action: ScenarioAction) => action.value.id === feature.id) as ScenarioAction;
                    Vue.set(action, 'value', feature);
                });
            });

            // Listen for the Escape key press event and cancel action.
            window.addEventListener('keydown', this.escapeKeyDown);

            this.setCreateActionLayersEvents("transit-roads", FeatureType.ROAD);
        }
    };

    enableDraw() {
        if (this.draw.getMode() !== 'draw_polygon') {
            this.draw.changeMode('draw_polygon');
        }
    };

    disableDraw() {
        if (this.draw.getMode() === 'draw_polygon') {
            this.draw.changeMode('simple_select');
        }
    };

    // Cancels an action by pressing the Escape button.
    escapeKeyDown(e: any) {
        // Check if the pressed key is Escape (key code 27).
        if (e.keyCode === 27) {
            this.disableDraw();
            this.creating_action = null;
        }
    };

    // Create an action by selecting a feature on the main map.
    setCreateActionLayersEvents(layer_e: any, feature_type: FeatureType) {

        var vm = this;
        this.getMap.on('click', layer_e, function (e: any) {
            if (vm.creating_action && vm.creating_action != StrategyType.AREA && e.features.length > 0) {
                var properties = e.features[0].properties;
                if (vm.creating_action === StrategyType.FEATURE_ID) {
                    bus.$emit('scenario_close_segment', properties, feature_type);
                } else if (vm.creating_action === StrategyType.ROAD) {
                    if (properties.street_name) { // Handle unnamed streets
                        bus.$emit('scenario_close_road', properties, feature_type);
                    } else { 
                        this.error_msg = 'The road selected is Unnamed and cannot be added to the Scenario, add a segment instead.'
                    }
                }
                vm.creating_action = null;
            }
        });

        // Listen for the Escape key press event and cancel action
        window.addEventListener('keydown', this.escapeKeyDown);
    }



    //-------------------------------------------
    //---------      Miscellaneous     ----------
    //-------------------------------------------

    // Event Handler for when the map loading state has changed.
    onMapLoading(e: any) {
        this.loading_map = e
    };

    // Event Handler for when the scenario studio has closed.
    onClose() {
        bus.$emit('scenario_studio', false);
        this.error_msg = "";
    };

    // Event Handler to display the list of current tasks.
    onShowTaskList() {
        bus.$emit('task_list_dialog', true);
    };

}

