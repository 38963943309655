































































































































































































































































































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

import {
    MglMap,
    MglNavigationControl,
    MglGeolocateControl,
    MglScaleControl,
    MglFullscreenControl,
    //MglVectorLayer,
    MglRasterLayer,
    MglGeojsonLayer,
    MglPopup,
    MglMarker
} from 'vue-mapbox'

import MglVectorLayer from './MglVectorLayer.vue' // Use our custom version of the vector layer.

import MglGeocoderControl from 'vue-mapbox-geocoder'

var mapboxgl = require('mapbox-gl');

import InfoPanel from './infopanels/InfoPanel.vue';

import Filters from './filters/Filters.vue';
import TransitFilter from './filters/Filter.vue';

import Events from './events/Events.vue';
import InteractiveLegend from './events/InteractiveLegend.vue';
import ContextReports from './events/ContextReports.vue';
import EnterpriseLegendEvents from './events/EnterpriseLegendEvents.vue';

import BlockedCommodityChart from './events/BlockedCommodityChart.vue';

import NetworkLegend from './legends/NetworkLegend.vue';
import EnterpriseLegend from './legends/EnterpriseLegend.vue';

import Reports from './reports/Reports.vue';

import ScenarioStudio from './scenarios/ScenarioStudio.vue';

import { isNullOrUndefined } from 'util';
import { DensityType } from '@/store/datasets/types';

import { State, Action, Getter } from 'vuex-class';
import { DatasetState } from '@/store/datasets/types';
import { FiltersState } from '@/store/filters/types';
import { MapState, Layers, Sources } from '@/store/map/types';
import { EventsState } from '@/store/events/types';

import { paintStyles } from '@/components/layers/paint_styles';

import { bus } from '@/pages/transitweb/main'

import { endpoints } from "@/endpoints";
import buildUrl from "build-url";
import * as enterprise_layers from './layers/enterprises';
import * as road_layers from './layers/roads';
import * as rail_layers from './layers/rail';
import * as sea_layers from './layers/sea';

import { CancelTokenSource, AxiosResponse, AxiosRequestConfig } from 'axios';
import { ScenarioAction, Scenario, FeatureType } from './scenarios/types';

@Component({
    components: {
        MglMap,
        MglGeocoderControl,
        MglNavigationControl,
        MglGeolocateControl,
        MglScaleControl,
        MglFullscreenControl,
        MglPopup,
        MglMarker,
        InfoPanel,
        Filters,
        TransitFilter,
        Events,
        InteractiveLegend,
        ContextReports,
        NetworkLegend,
        EnterpriseLegend,
        EnterpriseLegendEvents,
        MglVectorLayer,
        MglRasterLayer,
        MglGeojsonLayer,
        Reports,
        BlockedCommodityChart,
        ScenarioStudio
    }
})


export default class TransitMap extends Vue {
    @State('filters') filters!: FiltersState;
    @Action('filters/setFiltersNotDirty') setFiltersNotDirty: any;
    @Action('filters/setFiltersDirty') setFiltersDirty: any;
    @Action('filters/clearFilters') clearFilters: any;
    @Action('filters/setQueryParams') setFiltersQueryParams: any;
    @Action('filters/setFilterValue') setFilterValue: any;
    @Action('filters/setFilterParams') setFilterParams: any;
    @Getter('filters/isFiltered') isFiltered!: boolean;
    @Getter('filters/isMonthlyFilter') isMonthlyFilter!: boolean;
    @Getter('filters/hasUnconstrainedFilters') hasUnconstrainedFilters!: boolean;
    @Getter('filters/filterHasValue') filterHasValue: any; // Returns an anonymous function

    @Getter('auth/constrainedValues') constrainedValues: any; // Returns an anonymous function
    @Getter('auth/username') username!: string;

    @State('map') mapstate!: MapState;
    @Action('map/setMarker') setMarker: any;
    @Action('map/setFeatureOfInterest') setFeatureOfInterest: any;
    @Action('map/setFlyTo') setFlyTo: any;
    @Getter('map/isInspecting') isInspecting!: boolean;

    @Action('auth/logout') logout: any;
    @Action('auth/refreshToken') refreshToken: any;
    @Getter('auth/isAdmin') isAdmin?: boolean;
    @Getter('auth/isAcsUser') isAcsUser!: boolean;
    @Getter('auth/isAllowedSensitiveInfo') isAllowedSensitiveInfo!: boolean;
    @Getter('auth/isScenarioUser') isScenarioUser!: boolean;
    @Getter('auth/displayName') displayName!: string;

    @State('datasets') datasets!: DatasetState;
    @Action('datasets/getDatasets') getDatasets: any;

    @State('events') events!: EventsState;
    @Action('events/setBlockedEnterpriseId') setBlockedEnterpriseId: any;
    @Getter('events/isInspectingBlockedEnterprise') isInspectingBlockedEnterprise!: boolean;
    @Getter('events/hasEventDates') hasEventDates!: boolean;

    created() {

        this.addAxiosInterceptors(); // Intercepts axios calls to control the progress bar.

        var vm = this;

        this.setFiltersQueryParams({}) // Reset the previous map state params.

        this.getDatasets()

        if (this.isFiltered) {
            if (this.hasUnconstrainedFilters) {
                this.setFiltersDirty()
            }
        } else {
            this.setFiltersNotDirty()
        }

        // Refresh map after filter lists were updated on density type change.
        bus.$on('filter_lists_updated', (e: any) => {
            this.refreshMap();

        });

        // If filters are updated to a reset state (i.e. nothing selected), then refresh the base map.
        bus.$on('filters_updated', (filterId: string, value: any) => {
            //Update only if a user has all constrained filters (their account has constraints and they are looking at the base map).
            if (!this.hasUnconstrainedFilters) {
                // Cancel critical link analysis request. If request doesn't exist, nothing will be done.
                this.cancelCachedTileRequest();
                this.refreshMap();
            }
        });

        // Open or close the Scenario Studio.
        bus.$on('scenario_studio', (val: boolean) => {
            this.scenario_studio = val;
        });

        // Fly to bounds.
        bus.$on('map_bounds', (e: any) => {
            this.onFlyToBounds(e);
        });

        // Highlight feature.
        bus.$on('highlight_action', (e: Scenario) => {
            this.scenarioSingleAction = e;
            this.$data.map.once('idle', this.toggleFeatureOpacity(e));
        });

        this.initializeHighlightLayers();
    };


    destroyed() {
        bus.$off('filters_updated');
    };

    accessToken = 'pk.eyJ1IjoiYm9uMTMyIiwiYSI6ImNqdXRhYmw1OTA1eGUzeW5yZGo3OWZmankifQ.RYbaSeGdz3Nq_hIGWXrpSw';
    //mapStyleSatellite = 'mapbox://styles/bon132/cl311ia6i000h14nwms75g3rj';
    mapStyle = 'mapbox://styles/bon132/ckspigz2k5vsm18mjec6j8msb';

    center = [132.0, -29.5];
    bounds = [
        //Australia
        [60, -60], // Southwest coordinates
        [207, 20]  // Northeast coordinates
    ];


    isMapLoaded: boolean = false;
    isSatelliteLayer: boolean = false;
    show_layers = false;
    marker: any;
    enableMarker: boolean = true;
    mouseHoverLocation = { lng: 0, lat: 0 };
    errorSnackbar = false;
    errorMsg = "Errors have occurred retrieving data.";
    loading = { tiles: false };
    isSelfRefreshing = [DensityType.Road_Events_Baseline, DensityType.Road_Events_Scenario];  // Density types which don't require refreshing because they have their own refresh handling (i.e Events after retrieving the date list)
    DensityType: any = DensityType;
    popups: any = {};
    scenario_studio: boolean = false;

    cache_set_id: any = undefined;
    axiosRequestProgressBar: boolean = false;

    // Used to cancel cached tile requests.
    cachedTileCancelTokenSource: CancelTokenSource | null = null;

    scenarioSingleAction: Scenario | null = null;
    highlight_layers: any = {};

    @Watch('dataset', { deep: true })
    onDatasetChanged(val: any, oldVal: any) {
        if (this.isMapLoaded) { // Needed in case it's the first time a user has ever logged in. (The dataset will go from 'empty string' to a 'value string')
            this.loadNewMap();
        }
    }

    @Watch('isMapReady')
    onIsMapReady(val: boolean) {
        if (val) {
            this.loadBoundaries();
            if (!this.isSelfRefreshing.includes(this.densityType)) {
                this.refreshMap();
            }
            if (this.isInspecting) {
                this.renderLocationMarker(this.mapstate.marker)
            }
        }
    }


    @Watch('densityType')
    onDensityTypeChanged(val: DensityType, oldVal: DensityType) {

        // Clear the special filters which may exist (but not the value) on next/previous density types.
        this.setFilterValue({ filter: 'critical_link', value: [] })
        this.setFilterValue({ filter: 'inbound', value: [] })
        this.setFilterValue({ filter: 'outbound', value: [] })

        // Clear filters that cannot be shared between density types.
        this.setFilterValue({ filter: 'roadname', value: [] })
        this.setFilterValue({ filter: 'railname', value: [] })
        this.setFilterValue({ filter: 'sealane', value: [] })
        this.setFilterValue({ filter: 'gauge', value: [] })
        this.setFilterValue({ filter: 'accesstype', value: [] })
        this.setFilterValue({ filter: 'surfacetype', value: [] })

        // Clear filters which shouldn't be used for a certain density types.
        if (val == DensityType.Road_Events_Baseline || val == DensityType.Road_Events_Scenario) {
            this.setFilterValue({ filter: 'month', value: [] })
            this.setFilterValue({ filter: 'boundary1', value: [] })
            this.setFilterValue({ filter: 'boundary2', value: [] })
            this.setFilterValue({ filter: 'boundary3', value: [] })
            this.setFilterValue({ filter: 'boundary4', value: [] })
            this.setFilterValue({ filter: 'boundary5', value: [] })
            this.setFilterValue({ filter: 'boundary6', value: [] })
            this.setFilterValue({ filter: 'boundary7', value: [] })
        }

        // Update filters that are used in all density types but can have different filter values.
        if (val == DensityType.Road || val == DensityType.Rail || val == DensityType.Sea) {

            this.setFilterParams({ filter: 'commodity', params: { } });
            this.setFilterParams({ filter: 'commod_l2', params: { } });
            this.setFilterParams({ filter: 'commod_l3', params: { } });
            this.setFilterParams({ filter: 'origenterprise', params: { } });
            this.setFilterParams({ filter: 'origenterprisecategory', params: { } });
            this.setFilterParams({ filter: 'destenterprise', params: { } });
            this.setFilterParams({ filter: 'destenterprisecategory', params: { } });
        }

        this.show_layers = false; // Turn off the layers until it handles it's own map refresh itself. refreshMap is triggered (via bus) after filter lists were updated or, if Events, by its own refreshMap.

        //if (this.isSelfRefreshing.includes(val)) {
        //    this.show_layers = false;   // Turn off the layers until it handles it's own map refresh itself. Used for Events, Events have their own maprefresh.
        //}
        //else {
        //   this.refreshMap();          // Do a map refresh.
        //}

        this.clearLocationMarker();
    }


    @Watch('mapstate.hiddenLayers', { deep: false })
    onVisibilityChanged(val: string[], oldVal: string[]) {

        let turned_off = val.filter((x: string) => !oldVal.includes(x));
        for (var layer in turned_off) {
            this.$data.map.setLayoutProperty(turned_off[layer], 'visibility', 'none')
        }
        let turned_on = oldVal.filter((x: string) => !val.includes(x));
        for (var layer in turned_on) {
            this.$data.map.setLayoutProperty(turned_on[layer], 'visibility', 'visible')
        }
    }


    @Watch('mapstate.flyTo', { deep: false })
    onFlyToChanged(val: number[], oldVal: number[]) {
        if (val.length > 0) {
            this.$data.map.flyTo({
                center: val,
                zoom: 15
            });
            this.setFlyTo([]);
        }
    }

    onFlyToBounds(bounds: any) {
        if (bounds) {
            this.$data.map.fitBounds(bounds, {
                padding: 200,
            });
        }
    }

    // A consistent reference to the map object.
    get getMap() {
        return this.$data.map
    }


    get dataset(): any {
        return this.datasets.dataset;
    }


    get isMapReady(): boolean {
        return this.dataset != '' && this.dataset && this.isMapLoaded && !isNullOrUndefined(this.$data.map);
    }


    get densityType(): DensityType {
        return this.datasets.type;
    }


    get statisticType(): string {
        if (this.isMonthlyFilter) {
            return 'Monthly';
        } else if (this.densityType === DensityType.Road_Events_Baseline || this.densityType === DensityType.Road_Events_Scenario) {
            return 'Weekly'
        } else {
            return 'Annual';
        }
    }


    loadBoundaries() {
        this.$data.map.addSource('boundaries-source', this.boundariesSource);
    }


    removeBoundaries() {

        const source = 'boundaries-source'

        var layersToRemove = this.$data.map.getStyle().layers.filter(function (layer: any) {
            return layer.source === source;
        });

        for (var layer in layersToRemove) {
            this.$data.map.removeLayer(layersToRemove[layer].id);
        }

        this.$data.map.removeSource(source)
    }


    get boundariesSource(): any {
        return {
            type: 'vector',
            tiles: this.getTileUrl(endpoints.boundariesTileUrlTemplate(this.dataset), true, true), // Need to get the dataset param.
            tolerance: 40,
            buffer: 40,
        }
    }


    resetMap() {
        this.removeBoundaries();
        this.show_layers = false;
        this.popups = {};
        this.clearLocationMarker();
    }

    //refreshMap() {
    //    // Removes all mgl-vector-layer components (and hence the layers from Mapbox)
    //    this.show_layers = false;

    //    var info_panel = (this.$refs.info_panel as InfoPanel)
    //    if (!isNullOrUndefined(info_panel)) { info_panel.fetchLinkStats(); }

    //    // Need to do this on nextTick so the layers can be successfully removed when mgl-vector-layers are destroyed.
    //    Vue.nextTick(() => {
    //        for (let layerConfig in this.vector_layers) {
    //            var layer = (this.vector_layers as any)[layerConfig];

    //            // Remove the existing source.
    //            try {
    //                this.$data.map.removeSource(layer.sourceId)
    //            } catch (error) {
    //                //console.log(error)
    //            }
    //            // Update the source with the new url's.
    //            if (!isNullOrUndefined(layer.source)) {
    //                layer.source.tiles = this.getTileUrl(layer.endpoint(layer.args), layer.ignoreFilters);
    //            }
    //            // Save all params to the store.
    //            this.setFiltersQueryParams(this.getQueryParams(false, true));
    //        }

    //        // Reload all layers and sources.
    //        this.show_layers = true;

    //        // Need to do this on the nextTick so the layers can be successfully created when mgl-vector-layers are created.
    //        Vue.nextTick(() => {
    //            // Hide the layer if it has been disabled.
    //            for (var layer of this.mapstate.hiddenLayers) {
    //                if (this.$data.map.getLayer(layer)) {
    //                    this.$data.map.setLayoutProperty(layer, 'visibility', 'none')
    //                }
    //            }
    //        })
    //    })

    //    this.popups = {};
    //    this.setFiltersNotDirty();
    //};

    refreshMapMain(cache_set_id?: undefined) {
        // Removes all mgl-vector-layer components (and hence the layers from Mapbox)
        this.show_layers = false;

        if (cache_set_id) {
            this.cache_set_id = cache_set_id;
        }

        var info_panel = (this.$refs.info_panel as InfoPanel)
        if (!isNullOrUndefined(info_panel)) { (info_panel as any).fetchLinkStats(); }

        // Need to do this on nextTick so the layers can be successfully removed when mgl-vector-layers are destroyed.
        Vue.nextTick(() => {
            for (let layerConfig in this.vector_layers) {
                var layer = (this.vector_layers as any)[layerConfig];

                // Remove the existing source.
                try {
                    this.$data.map.removeSource(layer.sourceId)
                } catch (error) {
                    //console.log(error)
                }

                // Update the source with the new url's.
                if (!isNullOrUndefined(layer.source)) {
                    layer.source.tiles = this.getTileUrl(layer.endpoint(layer.args()), layer.ignoreFilters, false, layer.useCacheSet, layer.boundaryFilters);
                }

                // Save all params to the store.
                this.setFiltersQueryParams(this.getQueryParams(false, true));
            }

            // Reload all layers and sources.
            this.show_layers = true;

            // Need to do this on the nextTick so the layers can be successfully created when mgl-vector-layers are created.
            Vue.nextTick(() => {
                // Hide the layer if it has been disabled.
                for (var layer of this.mapstate.hiddenLayers) {
                    if (this.$data.map.getLayer(layer)) {
                        this.$data.map.setLayoutProperty(layer, 'visibility', 'none')
                    }
                }

                bus.$emit('update_enterprise_visbility')
            })
        })

        this.popups = {};
        this.setFiltersNotDirty();
    };


    refreshMap() {
        if (this.isFiltered && this.densityType !== DensityType.Road_Events_Baseline && this.densityType !== DensityType.Road_Events_Scenario) {

            this.cancelCachedTileRequest();// Cancel a running data table request.
            this.show_layers = false;
            this.setFiltersNotDirty();

            this.getCachedTile().then((response: any) => {
                if (response.data) {
                    this.refreshMapMain(response.data);
                }
            })
            .catch((error: any) => {
                console.log('Error fetching cached data:', error.message);
            });

        } else {
            this.refreshMapMain();
        }
    };

    // Filters optimisation - generate data before generating tiles for any baseline filter query (events don't use this feature)
    getCachedTile() {

        return new Promise((resolve, reject) => {

            // Generate cancel token source to cancel running data request when needed
            var CancelToken = Vue.axios.CancelToken;
            var CancelTokenSource = CancelToken.source();
            this.cachedTileCancelTokenSource = CancelTokenSource;

            var endpoint = buildUrl(endpoints.cachedTileUrl(this.dataset, this.densityType), {
                queryParams: this.getQueryParams(false, true)
            });

            Vue.axios({
                url: buildUrl(endpoint), cancelToken: CancelTokenSource.token
            }).then((response: any) => {
                resolve(response);
            }, (error: any) => {

                reject(error);

                if (Vue.axios.Cancel) {
                    console.log('Cached data request canceled.', error.message);
                } else {
                    console.error('Error:', error.message);
                    // If an error occurred at this stage, clear filters and load a new map.
                    this.loadNewMap();
                }
            });
        });
    };

    // Cancel Vue.axios request if critical link filter is removed and no other filters are selected
    cancelCachedTileRequest() {
        if (this.cachedTileCancelTokenSource) {
            this.cachedTileCancelTokenSource.cancel();
        }
    };


    loadNewMap() {
        this.resetMap();
        this.clearFilters();
        this.isMapLoaded = false;
        Vue.nextTick(() => { this.isMapLoaded = true; }) // Briefly toggle the isMapLoaded variable to cause isMapReady to watcher to trigger.
        this.refreshMap();
    };


    getQueryParams(ignoreFilters: boolean = false, ignoreEvents: boolean = false, useCacheSet: boolean = false, boundaryFilters: boolean = false) {

        // Build the tile url based upon dataset/filters/events values
        var queryParams: any = {}

        // If not ignoring Filter parameters when needed?
        if ((!ignoreFilters) || (ignoreFilters && useCacheSet && this.isFiltered)) {
            for (var filterId in this.filters.filter_types) {
                if (this.filters.filter_types.hasOwnProperty(filterId)) {
                    if (this.filters.filter_types[filterId].enabled && this.filterHasValue(filterId)) {
                        if (filterId.startsWith('boundary')) {
                            // Refactor Notes: This isn't cool... it works, but should think of a way to better handle boundary filters.
                            if (isNullOrUndefined(queryParams['boundary'])) { queryParams['boundary'] = [] }
                            var ids = this.filters.filter_types[filterId].value.map(function (val: any) { return val; });
                            queryParams['boundary'] = queryParams['boundary'].concat(ids);
                        } else {
                            // If boundaryFilter is false, add all filters to a layer, otherwise only boundary filters, if selected, will be added in the code block above.
                            if (!boundaryFilters) {
                                queryParams[filterId] = this.filters.filter_types[filterId].value.map(function (val: any) { return val; });

                            }
                        }
                    }
                }
            }
        }

        // Add cache_set_id as a parameter for filter queries.
        if(ignoreFilters && useCacheSet && this.isFiltered){
            queryParams['cache_set_id'] = this.cache_set_id;
        }

        // If not ignoring Events parameters when needed?
        if (!ignoreEvents) {
            if (this.densityType === DensityType.Road_Events_Baseline || this.densityType === DensityType.Road_Events_Scenario) {
                queryParams['date'] = this.events.event_date;

                //if (this.events.report_on === 'events') {
                //    queryParams['report_type'] = this.events.report_type;
                //}

                if (this.events.report_on === 'delta_trailers') {
                    this.events.delta_trailers != 'all' ? queryParams['commod_l3'] = this.events.delta_trailers : null;
                    /*queryParams['report_type'] = 'Event'; // Need to set as event for the enterprises*/
                }
            }
        }

        return queryParams;
    }

    getTileUrl(url: string, ignoreFilters: boolean = false, ignoreEvents: boolean = false, useCacheSet: boolean = false, boundaryFilters: boolean = false): string[] {
        var queryParams = this.getQueryParams(ignoreFilters, ignoreEvents, useCacheSet, boundaryFilters); // Get only required params for layer.
        var tileurl = buildUrl(url, { queryParams: queryParams });
        return [tileurl]; // Needs to be array of strings https://docs.mapbox.com/mapbox-gl-js/style-spec/sources/#vector-tiles
    }

    //-------------------------------------------
    //---------  Map Layer Definitions ----------
    //-------------------------------------------

    get vector_layers(): any {

        var vm = this;

        var ret = {

            [Layers.Enterprises]: {
                isVisible: () => {
                    return vm.densityType === DensityType.Road || vm.densityType === DensityType.Rail || vm.densityType === DensityType.Sea
                },
                layerId: Layers.Enterprises,
                layer: enterprise_layers.enterpriseStyle(Sources.Enterprises),
                sourceId: Sources.Enterprises,
                source: {
                    tiles: [], // Tiles get updated when layers are displayed.
                    tolerance: 40,
                    buffer: 40,
                },
                before: 'road-label', // Should be underneath road labels otherwise you can't properly read the labels when zoomed out along way.
                endpoint: endpoints.enterprisesTileUrlTemplate,
                args: () => {
                    return { dataset: this.dataset, densityType: this.densityType }
                },
                added: (e: any) => { this.setInfoPanelLayerEvents(e); }
            },

            [Layers.EventsEnterprisesBaseline]: {
                isVisible: () => {
                    return vm.densityType === DensityType.Road_Events_Baseline && (this.events.report_on === 'events' && this.events.report_type === 'Baseline')
                },
                layerId: Layers.EventsEnterprisesBaseline,
                layer: enterprise_layers.EventsEnterprisesBaselineStyle(Sources.EventsEnterprisesBaseline),
                sourceId: Sources.EventsEnterprisesBaseline,
                source: {
                    tiles: [], // Tiles get updated when layers are displayed.
                    tolerance: 40,
                    buffer: 40,
                },
                before: 'road-label', // Should be underneath road labels otherwise you can't properly read the labels when zoomed out along way.
                endpoint: endpoints.eventsBaselineEnterprisesTileUrlTemplate,
                args: () => {
                    return { dataset: this.dataset, densityType: this.densityType }
                },
                added: (e: any) => { this.setInfoPanelLayerEvents(e); }
            },

            [Layers.Roads]: {
                isVisible: () => {
                    return vm.densityType === DensityType.Road
                },
                layerId: Layers.Roads,
                layer: road_layers.roadStyle(Sources.Roads),
                sourceId: Sources.Roads,
                source: {
                    tiles: [], // Tiles get updated when layers are displayed.
                    tolerance: 40,
                    buffer: 40,
                    maxzoom: 12,
                },
                before: Layers.Enterprises, // Ensure enterprises are over roads.
                endpoint: endpoints.roadsTileUrlTemplate,
                args: () => {
                    return { dataset: this.dataset }
                },
                legendStyles: ['trailer_loads', 'tonnes', 'tonnes_per_trailer', 'trip_transport_costs', 'trip_freight_value', 'trip_avg_distance', 'trip_avg_duration', 'co2_tn', 'truck', 'surface', 'road_speed', 'road_rank'],
                useCacheSet: true,
                ignoreFilters: true,
                boundaryFilters: true, // Defines if boundary filters are needed when other filters are ignored.
                added: (e: any) => { this.setInfoPanelLayerEvents(e); }
            },

            [Layers.RoadsBackground]: {
                isVisible: () => {
                    return vm.densityType === DensityType.Road
                },
                layerId: Layers.RoadsBackground,
                layer: road_layers.roadShadowsStyle(Sources.RoadsBackground),
                sourceId: Sources.RoadsBackground,
                source: {
                    tiles: [], // Tiles get updated when layers are displayed.
                    tolerance: 40,
                    buffer: 40,
                },
                before: Layers.Roads,
                endpoint: endpoints.roadsTileUrlTemplate,
                args: () => {
                    return { dataset: this.dataset }
                },
                ignoreFilters: true,
            },

            [Layers.Rail]: {
                isVisible: () => {
                    return vm.densityType === DensityType.Rail
                },
                layerId: Layers.Rail,
                layer: rail_layers.railStyle(Sources.Rail),
                sourceId: Sources.Rail,
                source: {
                    tiles: [], // Tiles get updated when layers are displayed.
                    tolerance: 40,
                    buffer: 40,
                },
                before: Layers.Enterprises, // Ensure enterprises are over rail lines.
                endpoint: endpoints.railTileUrlTemplate,
                args: () => {
                    return { dataset: this.dataset }
                },
                useCacheSet: true,
                ignoreFilters: true,
                boundaryFilters: true, // Defines if boundary filters are needed when other filters are ignored.
                legendStyles: ['trailer_loads', 'tonnes', 'tonnes_per_trailer', 'trip_transport_costs', 'trip_freight_value', 'trip_avg_distance', 'trip_avg_duration', 'co2_tn', 'rail_speed', 'gauge'],
                added: (e: any) => { this.setInfoPanelLayerEvents(e); }
            },

            [Layers.RailBackground]: {
                isVisible: () => {
                    return vm.densityType === DensityType.Rail
                },
                layerId: Layers.RailBackground,
                layer: rail_layers.railShadowsStyle(Sources.RailBackground),
                sourceId: Sources.RailBackground,
                source: {
                    tiles: [], // Tiles get updated when layers are displayed.
                    tolerance: 40,
                    buffer: 40,
                },
                before: Layers.Rail,
                endpoint: endpoints.railTileUrlTemplate,
                args: () => {
                    return { dataset: this.dataset }
                },
                ignoreFilters: true,
            },


            [Layers.Sea]: {
                isVisible: () => {
                    return vm.densityType === DensityType.Sea
                },
                layerId: Layers.Sea,
                layer: sea_layers.seaStyle(Sources.Sea),
                sourceId: Sources.Sea,
                source: {
                    tiles: [], // Tiles get updated when layers are displayed.
                    tolerance: 40,
                    buffer: 40,
                },
                before: Layers.Enterprises, // Ensure enterprises are over sea lines.
                endpoint: endpoints.seaTileUrlTemplate,
                args: () => {
                    return { dataset: this.dataset }
                },
                useCacheSet: true,
                ignoreFilters: true,
                boundaryFilters: true, // Defines if boundary filters are needed when other filters are ignored.
                legendStyles: ['trailer_loads', 'tonnes', 'tonnes_per_trailer', 'trip_transport_costs', 'trip_freight_value', 'co2_tn'],
                added: (e: any) => { this.setInfoPanelLayerEvents(e); }
            },

            [Layers.SeaBackground]: {
                isVisible: () => {
                    return vm.densityType === DensityType.Sea
                },
                layerId: Layers.SeaBackground,
                layer: sea_layers.seaShadowsStyle(Sources.SeaBackground),
                sourceId: Sources.SeaBackground,
                source: {
                    tiles: [], // Tiles get updated when layers are displayed.
                    tolerance: 40,
                    buffer: 40,
                },
                before: Layers.Sea, // Ensure sea lines are over the sea line shadows.
                endpoint: endpoints.seaTileUrlTemplate,
                args: () => {
                    return { dataset: this.dataset }
                },
                ignoreFilters: true,
            },

            [Layers.RoadEventsBaseline]: {
                isVisible: () => {
                    return vm.densityType === DensityType.Road_Events_Baseline  && vm.events.event_date != '' && this.events.report_on === 'events'
                },
                layerId: Layers.RoadEventsBaseline,
                layer: road_layers.roadEventsBaselineStyle(Sources.RoadEventsBaseline),
                sourceId: Sources.RoadEventsBaseline,
                source: {
                    tiles: [], // Tiles get updated when layers are displayed.
                    tolerance: 40,
                    buffer: 40,
                },
                before: this.events.report_type === 'Baseline' ? Layers.EventsEnterprisesBaseline : '',
                endpoint: endpoints.roadBaselineTileUrlTemplate,
                args: () => {
                    return { dataset: this.dataset, date: this.events.event_date }
                },
                legendStyles: ['trailer_loads', 'tonnes', 'tonnes_per_trailer', 'trip_transport_costs', 'trip_freight_value', 'trip_avg_distance', 'trip_avg_duration', 'co2_tn', 'truck', 'surface', 'road_speed', 'road_rank'],
                added: (e: any) => { this.setInfoPanelLayerEvents(e); }
            },
            [Layers.RoadEventsScenario]: {
                isVisible: () => {
                    return vm.densityType === DensityType.Road_Events_Scenario && vm.events.event_date != '' && this.events.report_on === 'events'
                },
                layerId: Layers.RoadEventsScenario,
                layer: road_layers.roadEventsScenarioStyle(Sources.RoadEventsScenario),
                sourceId: Sources.RoadEventsScenario,
                source: {
                    tiles: [], // Tiles get updated when layers are displayed.
                    tolerance: 40,
                    buffer: 40,
                },
                before: this.events.report_type === 'Baseline' ? Layers.EventsEnterprisesBaseline : '',
                endpoint: endpoints.roadScenarioTileUrlTemplate,
                args: () => {
                    return { dataset: this.dataset, date: this.events.event_date }
                },
                legendStyles: ['trailer_loads', 'tonnes', 'tonnes_per_trailer', 'trip_transport_costs', 'trip_freight_value', 'trip_avg_distance', 'trip_avg_duration', 'co2_tn', 'truck', 'surface', 'road_speed', 'road_rank'],
                added: (e: any) => { this.setInfoPanelLayerEvents(e); }
            },

            [Layers.DeltaTrailers]: {
                isVisible: () => {
                    return (vm.densityType === DensityType.Road_Events_Scenario || vm.densityType === DensityType.Road_Events_Baseline) && vm.events.event_date != '' && this.events.report_on === 'delta_trailers'
                },
                layerId: Layers.DeltaTrailers,
                layer: road_layers.roadEventsDeltaTrailersStyle(Sources.DeltaTrailers),
                sourceId: Sources.DeltaTrailers,
                source: {
                    tiles: [], // Tiles get updated when layers are displayed.
                    tolerance: 40,
                    buffer: 40,
                },
                endpoint: endpoints.eventsDeltaCommodTileUrlTemplate,
                args: () => {
                    return { dataset: this.dataset, date: this.events.event_date }
                },
                legendStyles: ['trailer_loads_difference'],
                ignoreFilters: true,
                added: (e: any) => { this.setRoadPopupLayerEvents(e); }
            },

            [Layers.ClosedRoads1]: {
                isVisible: () => {
                    return (vm.densityType === DensityType.Road_Events_Baseline || vm.densityType === DensityType.Road_Events_Scenario) && vm.events.event_date != ''
                },
                layerId: Layers.ClosedRoads1,
                layer: road_layers.roadEventsClosedRoadsStyle(Sources.ClosedRoads, Layers.ClosedRoads1, ['<=', ['get', 'days_closed'], 7]),
                sourceId: Sources.ClosedRoads,
                source: {
                    tiles: [], // Tiles get updated when layers are displayed.
                    tolerance: 40,
                    buffer: 40,
                },
                clearSource: false, // vector_layers is looped in order everywhere, so leave it until the last layer to remove a shared source.
                endpoint: endpoints.eventsClosedRoadsTileUrlTemplate,
                args: () => {
                    return { dataset: this.dataset, date: this.events.event_date }
                },
                ignoreFilters: true,
                added: (e: any) => { this.setRoadPopupLayerEvents(e); }
            },

            [Layers.ClosedRoads7]: {
                isVisible: () => {
                    return (vm.densityType === DensityType.Road_Events_Baseline || vm.densityType === DensityType.Road_Events_Scenario) && vm.events.event_date != ''
                },
                layerId: Layers.ClosedRoads7,
                layer: road_layers.roadEventsClosedRoadsStyle(Sources.ClosedRoads, Layers.ClosedRoads7, ['>=', ['get', 'days_closed'], 8]),
                sourceId: Sources.ClosedRoads,
                endpoint: endpoints.eventsClosedRoadsTileUrlTemplate,
                args: () => {
                    return { dataset: this.dataset, date: this.events.event_date }
                },
                ignoreFilters: true,
                added: (e: any) => { this.setRoadPopupLayerEvents(e); }
            },

            [Layers.BlockedEnterprisesOrigin]: {
                isVisible: () => {
                    return (vm.densityType === DensityType.Road_Events_Baseline || vm.densityType === DensityType.Road_Events_Scenario) && vm.events.event_date != ''
                },
                layerId: Layers.BlockedEnterprisesOrigin,
                layer: enterprise_layers.enterpriseBlockedODStyle(Sources.BlockedEnterprisesOD, Layers.BlockedEnterprisesOrigin, 0),
                sourceId: Sources.BlockedEnterprisesOD,
                source: {
                    tiles: [], // Tiles get updated when layers are displayed.
                    tolerance: 40,
                    buffer: 40,
                },
                clearSource: false, // vector_layers is looped in order everywhere, so leave it until the last layer to remove a shared source.
                before: this.events.report_on === 'events' && this.events.report_type === 'Baseline' ? Layers.EventsEnterprisesBaseline : '',
                endpoint: endpoints.eventsBlockedEnterprisesODTileUrlTemplate,
                args: () => {
                    return { dataset: this.dataset, densityType: this.densityType }
                },
                ignoreFilters: this.filters.disabled,
            },

            [Layers.BlockedEnterprisesDestination]: {
                isVisible: () => {
                    return (vm.densityType === DensityType.Road_Events_Baseline || vm.densityType === DensityType.Road_Events_Scenario) && vm.events.event_date != ''
                },
                layerId: Layers.BlockedEnterprisesDestination,
                layer: enterprise_layers.enterpriseBlockedODStyle(Sources.BlockedEnterprisesOD, Layers.BlockedEnterprisesDestination, 1),
                sourceId: Sources.BlockedEnterprisesOD,
                before: this.events.report_on === 'events' && this.events.report_type === 'Baseline' ? Layers.EventsEnterprisesBaseline : '',
                endpoint: endpoints.eventsBlockedEnterprisesODTileUrlTemplate,
                args: () => {
                    return { dataset: this.dataset, densityType: this.densityType }
                },
                ignoreFilters: this.filters.disabled,
            },

            [Layers.BlockedEnterprisesUnaffected]: {
                isVisible: () => {
                    return (vm.densityType === DensityType.Road_Events_Scenario || vm.densityType === DensityType.Road_Events_Baseline)  && vm.events.event_date != '' && (vm.events.report_type === 'Event' || vm.events.report_on === 'delta_trailers')
                },
                layerId: Layers.BlockedEnterprisesUnaffected,
                layer: enterprise_layers.enterpriseBlockedUnStyle(Sources.BlockedEnterprises),
                sourceId: Sources.BlockedEnterprises,
                source: {
                    tiles: [], // Tiles get updated when layers are displayed.
                    tolerance: 40,
                    buffer: 40,
                },
                clearSource: false, // vector_layers is looped in order everywhere, so leave it until the last layer to remove a shared source.
                endpoint: endpoints.eventsBlockedEnterprisesTileUrlTemplate,
                args: () => {
                    return { dataset: this.dataset, densityType: this.densityType }
                },
                ignoreFilters: this.filters.disabled,
                added: (e: any) => { this.setInfoPanelLayerEvents(e); }
            },

            [Layers.BlockedEnterprisesPartial]: {
                isVisible: () => {
                    return (vm.densityType === DensityType.Road_Events_Scenario || vm.densityType === DensityType.Road_Events_Baseline) && vm.events.event_date != '' && (this.events.report_type === 'Event' || this.events.report_on === 'delta_trailers')
                },
                layerId: Layers.BlockedEnterprisesPartial,
                layer: enterprise_layers.enterpriseBlockedParStyle(Sources.BlockedEnterprises),
                sourceId: Sources.BlockedEnterprises,
                clearSource: false,  // vector_layers is looped in order everywhere, so leave it until the last layer to remove a shared source.
                endpoint: endpoints.eventsBlockedEnterprisesTileUrlTemplate,
                args: () => {
                    return { dataset: this.dataset, densityType: this.densityType }
                },
                ignoreFilters: this.filters.disabled,
                added: (e: any) => { this.setInfoPanelLayerEvents(e); }
            },

            [Layers.BlockedEnterprisesTotally]: {
                isVisible: () => {
                    return (vm.densityType === DensityType.Road_Events_Scenario || vm.densityType === DensityType.Road_Events_Baseline) && vm.events.event_date != '' && (this.events.report_type === 'Event' || this.events.report_on === 'delta_trailers')
                },
                layerId: Layers.BlockedEnterprisesTotally,
                layer: enterprise_layers.enterpriseBlockedTotStyle(Sources.BlockedEnterprises),
                sourceId: Sources.BlockedEnterprises,
                endpoint: endpoints.eventsBlockedEnterprisesTileUrlTemplate,
                args: () => {
                    return { dataset: this.dataset, densityType: this.densityType }
                },
                ignoreFilters: this.filters.disabled,
                added: (e: any) => { this.setInfoPanelLayerEvents(e); }
            },

            [Layers.BlockedEnterprisesImpactedBy]: {
                isVisible: () => {
                    return (vm.densityType === DensityType.Road_Events_Scenario || vm.densityType === DensityType.Road_Events_Baseline) && vm.events.event_date != '' && (vm.events.report_type === 'Event' || this.events.report_on === 'delta_trailers') && this.isInspectingBlockedEnterprise
                },
                layerId: Layers.BlockedEnterprisesImpactedBy,
                layer: enterprise_layers.enterpriseBlockedImpactedByStyle(Sources.BlockedEnterprisesImpactedBy),
                sourceId: Sources.BlockedEnterprisesImpactedBy,
                source: {
                    tiles: [], // Tiles get updated when layers are displayed.
                    promoteId: { 'blockedenterprises_impactedby': 'street_name' }, // Generated feature Id from source layer.
                    tolerance: 40,
                    buffer: 40,
                },
                endpoint: endpoints.eventsBlockedEnterprisesImpactedByTileUrlTemplate,
                args: () => {
                    return { dataset: this.dataset, ent_id: this.events.blocked_enterprise_id }
                },
                added: (e: any) => { this.setBlockedEnterprisesImpactedByLayersEvents(e); }
            },

            [Layers.ClosureCategories]: {
                isVisible: () => {
                    return (vm.densityType === DensityType.Road_Events_Baseline || vm.densityType === DensityType.Road_Events_Scenario) && vm.events.event_date != ''
                },
                layerId: Layers.ClosureCategories,
                layer: road_layers.roadEventsClosureCategoriesStyle(Sources.ClosureCategories),
                sourceId: Sources.ClosureCategories,
                source: {
                    tiles: [], // Tiles get updated when layers are displayed.
                    tolerance: 40,
                    buffer: 40,
                    maxzoom: 12,
                },
                //before: Layers.Enterprises, // Ensure enterprises are over roads.
                endpoint: endpoints.eventsClosureCategoriesTileUrlTemplate,
                args: () => {
                    return { dataset: this.dataset, date: this.events.event_date }
                },
                ignoreFilters: true,
                added: (e: any) => { this.setRoadPopupLayerEvents(e); }
            },

        };

        // If the current legend layer has a paint style definition in the dictionary... (Otherwise assumes the paint style is already defined on the actual layer).
        if (this.mapstate.layerStyle in paintStyles) {
            if (this.layerForLegend != '') {
                (ret as any)[this.layerForLegend].layer.paint['line-color'] = (paintStyles as any)[this.mapstate.layerStyle]; // Set the line colors on the layers paint object.
            }
        }
        return ret
    }


    // Gets the layer to be used for the legend.
    get layerForLegend(): string {
        if (this.densityType === DensityType.Road_Events_Baseline && this.events.report_on === 'events') {
            return Layers.RoadEventsBaseline;
        } else if (this.densityType === DensityType.Road_Events_Scenario && this.events.report_on === 'events') {
            return Layers.RoadEventsScenario;
        } else if ((this.densityType === DensityType.Road_Events_Scenario || this.densityType === DensityType.Road_Events_Baseline) && this.events.report_on === 'delta_trailers') {
            return Layers.DeltaTrailers;
        } else if (this.densityType === DensityType.Road) {
            return Layers.Roads;
        } else if (this.densityType === DensityType.Rail) {
            return Layers.Rail;
        } else if (this.densityType === DensityType.Sea) {
            return Layers.Sea;
        } else {
            return ''
        }
    }


    setInfoPanelLayerEvents(layer_e: any) {
        var vm = this;
        vm.$data.map.on('click', layer_e.layerId, function (e: any) {
            // If not already inspecting a feature.
            if (!vm.isInspecting && vm.enableMarker) {  // !vm.actionToCreate - here it prevents dropping a pin if any of the Add Action buttons are in 'active' state (i.e. colored orange) in the Scenario Studio.
                vm.setMarker(e.lngLat)                  // Set the marker location in the store.
                vm.renderLocationMarker(e.lngLat);      // Draw the marker on the map.
            }
        });
    }


    setBlockedEnterprisesImpactedByLayersEvents(layer_e: any) {

        var featureId: any = null;

        var vm = this;

        // When the user moves their mouse over the layer, we'll update the
        // feature state for the feature under the mouse - this will color marker red
        vm.$data.map.on('mousemove', layer_e.layerId, function (e: any) {
            if (e.features.length > 0) {
                if (featureId != null) { layer_e.component.setFeatureState(featureId, { hover: false }); }
                featureId = e.features[0].id;
                layer_e.component.setFeatureState(featureId, { hover: true });
            }
        });

        // When the mouse leaves the layer, update the feature state of the
        // previously hovered feature - this will color marker grey
        vm.$data.map.on('mouseleave', layer_e.layerId, function (e: any) {
            if (featureId != null) { layer_e.component.setFeatureState(featureId, { hover: false }); }
            featureId = null;
        });

        vm.$data.map.on('click', layer_e.layerId, function (e: any) {

            var feature = e.features[0];

            // Create a popup with a closed link street name.
            Vue.nextTick(() => {
                Vue.set(vm.popups, layer_e.layerId, {
                    showed: true,
                    contentHTML: '<div id="impacted-by-popup-content"></div>',
                    coordinates: [e.lngLat.lng, e.lngLat.lat],
                    closeButton: false,
                    closeOnClick: true,
                    offset: 25,
                    open: (open: any) => {
                        // Inject the BlockedCommodityChart component into the popup.
                        const commodityChart = Vue.extend(BlockedCommodityChart)
                        const popupInstance = new commodityChart({
                            propsData: {
                                properties: feature.properties,
                            }
                        }).$mount("#impacted-by-popup-content"); // Mount this component to the div added above.
                    },
                    close: (close: any) => {
                        Vue.delete(vm.popups, layer_e.layerId);
                    },
                })
            })
        });
    }


    setRoadPopupLayerEvents(layer_e: any) {

        var vm = this;

        // Note: Extra mouseenter event, to add additional functionality to default.
        vm.$data.map.on('mouseenter', layer_e.layerId, function (e: any) {

            const street_name = e.features[0].properties.street_name;
            const trailer_loads = layer_e.layerId === Layers.DeltaTrailers ? (e.features[0].properties.trailer_loads).toFixed(2) : 0
            const display_name = e.features[0].properties.display_name;

            // Populate the popup and set its coordinates based on the feature found.
            const html_string_deltatrailers = "<h3>Road name: " + street_name + "</h3>" + "<h3>Change in trailers: " + trailer_loads + "</h3>";
            const html_string_closedroad = "<h3>Closed road: " + street_name + "</h3>";
            const html_string_closurecategory = "<h3>" + display_name + "</h3>";

            if (layer_e.layerId === Layers.DeltaTrailers) {
                var html_string = html_string_deltatrailers
            } else if (layer_e.layerId === Layers.ClosureCategories) {
                var html_string = html_string_closurecategory
            } else {
                var html_string = html_string_closedroad
            }

            //var html_string = layer_e.layerId === Layers.DeltaTrailers ? html_string_deltatrailers : html_string_closedroad;

            Vue.set(vm.popups, layer_e.layerId, {
                showed: true,
                contentHTML: html_string,
                closeButton: false,
                closeOnClick: false,
            })
        });

        // Note: Extra mouseleave event to add additional functionality to default.
        vm.$data.map.on('mouseleave', layer_e.layerId, function (e: any) {
            Vue.delete(vm.popups, layer_e.layerId);
        });
    }


    onCloseInfoPanel(type: string) {
        this.setFeatureOfInterest(null);
        this.clearLocationMarker();
        this.setBlockedEnterpriseId(undefined);
        if (!isNullOrUndefined(this.popups[Layers.BlockedEnterprisesImpactedBy])) {
            Vue.delete(this.popups, Layers.BlockedEnterprisesImpactedBy);
        }
    }


    clearLocationMarker() {
        if (this.isInspecting) {
            this.$data.marker.remove();
            this.$data.marker = null;
            this.setFeatureOfInterest(null);
            this.setMarker(null);
        }
    }

    renderLocationMarker(lngLat: any) {

        if (!this.$data.marker) {
            // create a HTML element for each feature
            var el = document.createElement('div');
            el.className = 'marker';

            // make a marker for each feature and add to the map
            this.$data.marker = new mapboxgl.Marker(el, {
                offset: [0, -42 / 2]
            })
                .setLngLat(lngLat)
                .addTo(this.$data.map);
        }
    }

    // Add the Auth token in all Mapbox Tile requests.
    addJWTToken(url: any, resourceType: any) { // Note: does not use Vue.axios that we have configured, so interceptors do not pick up 401 errors.

        var isHighlightAction = url.includes('highlight');

        var headers = {
            'Authorization': 'Bearer ' + this.$store.state.auth.access_token,
            'X-Username': this.$store.state.auth.user.username,
        };

        if (resourceType == 'Tile' && !url.includes('mapbox.com')) {

            // Make it a POST request when highlight action is requested
            if (isHighlightAction) {
                return {
                    url: url,
                    headers: {
                        ...headers,
                        'Content-Type': 'application/json',
                    },
                    method: 'POST',
                    body: JSON.stringify(this.scenarioSingleAction),
                };
            }

            // All other tile requests
            return {
                url: url,
                headers: headers,
            };
        }

    }


    loadMapImage(image_path: string, imageId: string, sdfOption: boolean = true) {

        this.$data.map.loadImage(image_path, (error: any, image: any) => {
            if (error) throw error;
            // Convert to a signed distance field to enable resizing and recoluring of monochrome images.
            // Images with a single colour and transparent background are appropriate.
            this.$data.map.addImage(imageId, image, { 'sdf': sdfOption });
        });
    }


    //-----------------------------------
    //---------  Mapbox Events ----------
    //-----------------------------------

    //Map Loaded
    onMapLoaded(e: any) {

        this.$data.map = e.map; // Do not add mapbox to a reactive property or else things go bad.

        // Load necessary custom markers.
        this.loadMapImage('./location_on_FILL1_wght400_GRAD0_opsz48.png', 'marker-icon', true)
        this.loadMapImage('./stop_FILL1_wght400_GRAD0_opsz48.png', 'stop_icon', true)
        this.loadMapImage('./change_history_FILL1_wght400_GRAD0_opsz48.png', 'triangle_icon', true)
        this.loadMapImage('./flood-icon-small.png', 'water_icon', false)
        this.loadMapImage('./fire-icon-small.png', 'fire_icon', false)
        this.loadMapImage('./hazard-icon-small.png', 'caution_icon', false)

        this.isMapLoaded = true;
    }


    // Mouse is moving on the map.
    onMapMouseMove(e: any) {
        // If not locked into inspecting a feature (i.e. mouse hovering)

        if (!this.isInspecting) {

            // Get the features at the mouse location.
            var features = this.$data.map.queryRenderedFeatures(e.mapboxEvent.point);

            // Ignore any mapbox features.
            features = features.filter(function (f: any) {
                if (f.source === "composite" || f.source.startsWith('mapbox-gl-draw')) {
                    return false; // skip
                }
                return true;
            });

            // If there is a feature at the mouse location, set the feature of interest.
            if (features.length > 0) {
                var feature = features[0]
                this.setFeatureOfInterest(feature);
            } else {
                this.setFeatureOfInterest(null);
            }
        }

        this.mouseHoverLocation = e.mapboxEvent.lngLat; // Capture the current coordinates.
    }


    // Get a name for the background map layer
    get mapBackgroundLayerName() {
        return this.isSatelliteLayer ? 'Map' : 'Satellite';
    }

    // Switch between background layers on the map
    toggleMapBackgroundLayer() {

        this.isSatelliteLayer = !this.isSatelliteLayer;

        if (!this.isSatelliteLayer) {
            // Remove layer explicitly
            this.$data.map.removeLayer('satellite');
        }
    };


    // Map is updating.
    onMapData(e: any) {
        if (!this.axiosRequestProgressBar){
            if (e.mapboxEvent.tile) {
                this.loading.tiles = !this.$data.map.areTilesLoaded()
            }
        }
    }

   // Map becomes idle.
   onMapIdle(e: any) {
       if (!this.axiosRequestProgressBar) {
           this.loading.tiles = !this.$data.map.areTilesLoaded()
       }
   }

    //// Map becomes idle.
    //onMapIdle(e: any) {
    //    this.loading.tiles = !this.$data.map.areTilesLoaded()
    //}

    onMapError(e: any) {
        if (e.mapboxEvent.error.status == 401) {
            this.logout(); // Deletes axios auth headers
            this.$router.push("/signin");
        }
    }

    // Interseptors are used here to control the progress bar on axios requests
    addAxiosInterceptors() {
        var requestInterceptor = Vue.axios.interceptors.request.use(
            (config: AxiosRequestConfig) => {
                this.axiosRequestProgressBar = true;
                this.loading.tiles = true;
                return config;
            });

        var responseInterceptor = Vue.axios.interceptors.response.use(
            (response: AxiosResponse<any>) => {
                this.axiosRequestProgressBar = false;
                this.loading.tiles = false;
                return response;
            },

            (error: any) => {
                this.axiosRequestProgressBar = false;
                this.loading.tiles = false;
                return Promise.reject(error);
            }
        );
      return { requestInterceptor, responseInterceptor };
    }

    //-------------------------------------------------
    //---------  Highlight action on the map ----------
    //-------------------------------------------------

    initializeHighlightLayers() {

        Vue.set(this, 'highlight_layers', {
            [Layers.HighlightSegments]: {
                isVisible: false,
                layerId: Layers.HighlightSegments,
                layer: road_layers.highlightSegmentsStyle(Layers.HighlightSegments, Sources.HighlightSegments),
                sourceId: Sources.HighlightSegments,
                source: {
                    tiles: [endpoints.scenarioActionsLayerUrl(this.dataset, 'highlight-segments')],
                    tolerance: 40,
                    buffer: 40,
                },
                paint: { 'line-opacity': 1 },
            },
            [Layers.HighlightEnterprises]: {
                isVisible: false,
                layerId: Layers.HighlightEnterprises,
                layer: enterprise_layers.highlightEnterprisesStyle(Layers.HighlightEnterprises, Sources.HighlightEnterprises),
                sourceId: Sources.HighlightEnterprises,
                source: {
                    tiles: [endpoints.scenarioActionsLayerUrl(this.dataset, 'highlight-enterprises')],
                    tolerance: 40,
                    buffer: 40,
                },
                paint: { 'circle-opacity': 1 },
            },
        });
    }

    toggleFeatureOpacity(scenario: Scenario) {

        var feature_type = scenario.actions[0].feature_type;
        var opacity_property = feature_type === FeatureType.ENTERPRISE ? 'circle-opacity' : 'line-opacity'
        var layerId = feature_type === FeatureType.ENTERPRISE ? Layers.HighlightEnterprises : Layers.HighlightSegments;

        this.highlight_layers[layerId].isVisible = true; // Reload the layer.
        var cycleCount = 0;
        var cycles = 3;

        // Toggle the line-opacity.
        var interval = setInterval(() => {
            var currentOpacity = this.highlight_layers[layerId].paint[opacity_property]
            var newOpacity = currentOpacity === 0 ? 1 : 0;

            // Reactively update the opacity property on the layer
            Vue.set(this.highlight_layers[layerId].paint, opacity_property, newOpacity);

            // Apply the updated paint property to the map layer directly (for some reason, using Vue.set on the layer's paint property alone does not trigger VueMapbox to update the layer visually)
            this.$data.map.setPaintProperty(layerId, opacity_property, newOpacity);

            if (cycleCount >= cycles * 2) {
                clearInterval(interval);
                this.highlight_layers[layerId].isVisible = false; //Remove the layer
            }
            cycleCount++;
        }, 500); // Interval (ms)
    }
}

