






































import { Component, Prop, Vue, Watch, Inject } from 'vue-property-decorator';

import LegendItemEnterprise from './LegendItemEnterprise.vue';
import { Layers } from '@/store/map/types';

import { DensityType } from '@/store/datasets/types';

import { bus } from '@/pages/transitweb/main'

Vue.config.productionTip = false

@Component({
    components: {
        LegendItemEnterprise
    }
})
export default class EnterpriseLegend extends Vue {
    @Prop() private getMap!: any;
    @Prop() private densityType!: DensityType;

    notVisibleValues: any = [];

    created() {

        // Update Enterprise visibility
        bus.$on('update_enterprise_visbility', (val: any) => {
            this.updateEnterpriseVisibility();
        });
    }

    destroyed() {
        bus.$off('update_enterprise_visbility');
    };

    @Watch('densityType')
    onDensityTypeChanged(val: DensityType, oldVal: DensityType) {
        this.resetIsVisible()
    }

    legendTitle(layer: string): string {
        return (this.layerStyles as any)[layer].title
    }

    // To do: We need to only show enterprises in the legend that exist on the density type, i.e. Shipping dataset does not have enterprise_category = 'processor',
    // therefore, it should not be shown in the Legend.
    // Legend bindings
    layerStyles: any = {
        enterprises: {
            title: "Enterprises",
            items: [
                { color: "#99ceff", value: "Processor", isVisible: true },
                { color: "#cc3300", value: "Manufacturer", isVisible: true },
                //{ color: "#FF69B4", value: "Mill", isVisible: true },
                { color: "#ff9999", value: "Storage", isVisible: true },
                { color: "yellow", value: "Transport", isVisible: true },
                { color: "#33FF8D", value: "Distribution", isVisible: true },
                { color: "red", value: "Retail", isVisible: true },
                { color: "#ffb31a", value: "Mine", isVisible: true },
                { color: "#8976cf", value: "Waste", isVisible: true },
                { color: '#C2B280', value: "Construction", isVisible: true },
                { color: '#fcc0a5', value: "Health", isVisible: true },
                { color: "#979A9A", value: "Production", isVisible: true },
            ],
            data_property: "enterprise_category"
        },
    }

    resetIsVisible() {
        this.notVisibleValues = [];
        this.layerStyles.enterprises.items.forEach((item: any) => {
            Vue.set(item, 'isVisible', true);
        });
        
    }
    
    toggleItemVisibility(layerStyleId:string, itemValue: string) {
        var item = this.layerStyles[layerStyleId].items.find((i: any) => i.value === itemValue);

        //Update the item's visibility
        Vue.set(item, 'isVisible', !item.isVisible);

        //Get visible items
        this.notVisibleValues = this.layerStyles[layerStyleId].items
            .filter((item: any) => !item.isVisible)
            .map((item: any) => item.value.toLowerCase());

        this.updateEnterpriseVisibility(layerStyleId)

    }

    updateEnterpriseVisibility(layerStyleId?: string) {
        var layerStyleId_ = layerStyleId ? layerStyleId : 'enterprises'
        // Apply the filter to the vector tile layer
        this.getMap.setFilter(Layers.Enterprises, [
            "!in",
            this.layerStyles[layerStyleId_].data_property, // Data property in the vector tile
            ...this.notVisibleValues // Visible items
        ]);
        
    }

}

