export var scenarioSegmentsStyle = (layerId: string, sourceId: string) => {


    return {
        id: layerId,
        type: 'line',
        source: sourceId,
        'source-layer': 'scenario-segments',
        layout: {
            'line-cap': 'round',
            'line-join': 'round',
        },
        paint: {
            'line-width': {
                stops: [
                    [7, 3],
                    [14, 14],
                    [18, 20]

                ],
            },
            'line-color': [
                'match',
                ['get', 'action_type'],
                'Close', 'red',
                'Alter', 'orange',
                'Build', 'green',
                '#000000' // Default color (black)
            ],
            'line-dasharray': [
                "match", ["get", "feature_type"],
                "Road", ["literal", [1, 0]],
                "Rail", ["literal", [0.2, 1.5]],
                "Sea", ["literal", [1.5, 3]],
                ["literal", [1, 0]]
            ]
        }
    };

};

export var scenarioEnterprisesStyle = (layerId: string, sourceId: string) => {

    return {
        id: layerId,
        type: 'circle',
        //filter: this.propertyFilter,
        source: sourceId,
        'source-layer': 'scenario-enterprises',
        'layout': {
            'visibility': 'visible',
        },
        paint: {
            'circle-radius': {
                stops: [
                    [6, 3],
                    [12, 6],
                    [16, 16]
                ],
            },
            'circle-stroke-width': 0.25,
            'circle-stroke-color': 'black',
            'circle-color': {
                property: 'action_type',
                type: 'categorical',
                default: 'black',
                stops: [
                    ['Close', 'red'],
                    ['Alter', 'orange'],
                    ['Build', 'green'],
                ]
            },
        }
    }
}