












































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

import VuePlotly from '@statnett/vue-plotly';

import { mixins } from 'vue-class-component';
import QueryEndpointData from './dataquery-mixin';

import BorderCrossingsBarChart from './BorderCrossingsBarChart.vue';

@Component({
    components: {
        VuePlotly,
        BorderCrossingsBarChart
    }
})
export default class SCEMap extends mixins(QueryEndpointData) {

    
    // This is only temporary... will need to hit an endpoint which runs the propogation model eventually.
    get densityMapUrl() {
        return process.env.VUE_APP_BACKEND_BASE + "/benchmarking/densitymapbm";
    }


    mounted() {
        // Load the map data when it is mounted.
        this.loadData(this.densityMapUrl);
    }


    @Watch('updateRequired')
    reloadData(newVal: any, oldVal: any) {
        // Reload the data
        this.loadData(this.densityMapUrl);
    }


    get mapData() {
        // Get the plotly data object for the appropriate supply chain leg map.

        return [{
            type: "choroplethmapbox",
            name: this.spatial_type.text,
            geojson: this.spatial_type.geojson,
            locations: this.data.map((item: any) => item[this.regions]),
            meta: [this.metric.valueformat.startsWith('$') ? '$' : '', this.metric.valuesuffix],
            text: this.data.map((item: any) => item["name"]),
            hovertemplate: "%{meta[0]}%{z}%{meta[1]}<extra>%{text}</extra>",
            //hoverinfo: "text+z+location",
            //textinfo: 'location+z+text',
            z: this.data.map((item: any) => item[this.metric.value]),
            colorscale: [[0, 'white'], [0.4, 'rgb(63,81,181)'], [1, 'red']],
        }]
    }


    get mapLayout() {
        // Get the plotly layout object for the appropriate supply chain leg map.

        return {
            mapbox: {
                style: 'light',
                center: {
                    lon: 134,
                    lat: -28.3
                },
                zoom: 3.0
            },
            height: 470,
            autosize: true,
            margin: { t: 6, b: 0, l: 10, r: 10 }
        };
    }


    densityMapOnClick(e: any) {
        // Probably have to do something here eventually...
    }

}


