import { render, staticRenderFns } from "./MovementsReport.vue?vue&type=template&id=49f28194&scoped=true"
import script from "./MovementsReport.vue?vue&type=script&lang=ts"
export * from "./MovementsReport.vue?vue&type=script&lang=ts"
import style0 from "./MovementsReport.vue?vue&type=style&index=0&id=49f28194&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "49f28194",
  null
  
)

export default component.exports