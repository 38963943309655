


















































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

import { ScenarioAction, StrategyType, ActionType, ParameterConfig, FeatureType } from './types'

import ActionParameter from './ActionParameter.vue';

@Component({
    components: {
        ActionParameter,
    },
})
export default class ActionEditor extends Vue {

    @Prop() private editingAction!: any;

    StrategyType: any = StrategyType;
    ActionType: any = ActionType;
    FeatureType: any = FeatureType;

    action: ScenarioAction | null = null;

    // Getter/setter for displaying/hiding the Action Editor dialog.
    get isActionEditor(): boolean {
        return Boolean(this.editingAction && this.action)
    }
    set isActionEditor(value: boolean) {
        if (!value) {
            this.onCloseActionEditor()
        }
    }

    get actionDescription() {

        // If it's a feature ID strategy for anything but an enterprise call it a segment.
        let strategy_type = this.action?.strategy_type === StrategyType.FEATURE_ID && this.action?.feature_type !== FeatureType.ENTERPRISE ? "Segment" : this.action?.strategy_type

        // If it's a GeoJSON strategy, then call it by it's feature type.
        strategy_type = this.action?.strategy_type === StrategyType.GEOJSON ? this.action?.feature_type : strategy_type;

        var description = this.action?.strategy_type === StrategyType.CLOSED_ROADS ? strategy_type + ' on' : strategy_type
        return description + " " + this.action?.description
    }

    @Watch('editingAction')
    onEditingActionChanged(newAction: ScenarioAction) {
        this.action = { ...newAction };
    }

    // The configuration for the parameters which can be edited.
    parameters: { [key: string]: ParameterConfig } = {
        road_speed: {
            label: 'Speed Limit (km/h)',
            hint: 'Change the road speed limit',
            type: 'select',
            select_classes: (() => {
                var speed_range = []
                for (let i = 5; i <= 110; i += 5) {
                    speed_range.push({ text: i.toString(), value: i });
                }
                return speed_range.reverse()
            })(),
            width: 3,
            worksWith: [FeatureType.ROAD,],
        },
        surface: {
            label: 'Surface Type',
            hint: 'Change the roads surface type',
            type: 'select',
            select_classes: [
                { text: 'Sealed', value: 'true' },
                { text: 'Unsealed', value: 'false' },
            ],
            width: 4,
            worksWith: [FeatureType.ROAD,],
        },
        truck: {
            label: 'Truck access',
            hint: 'Change the maximum trailer length',
            type: 'select',
            select_classes: [
                { text: 'Semi-Trailer (PBS1)', value: 'ST' },
                { text: 'B-Double (PBS2a)', value: 'BD' },
                { text: 'Type 1 Road Train (PBS3a)', value: 'T1' },
                { text: 'Type 2 Road Train (PBS4a)', value: 'T2' }
            ],

            width: 5,
            worksWith: [FeatureType.ROAD,],
        },

        road_rank: {
            label: 'Functional Class',
            hint: 'Change the volume/speed functional class',
            type: 'select',
            select_classes: [
                { text: 'FC1: Roads allow for high volume and maximum speed', value: 1 },
                { text: 'FC2: Roads allow for high volume and high speed', value: 2 },
                { text: 'FC3: Roads allow for high volume at a lower level of mobility', value: 3 },
                { text: 'FC4: Roads allow for high volume at moderate speeds between neighbourhoods', value: 4 },
                { text: 'FC5: Roads whose volume and traffic movement are below the level of any other FC', value: 5 }
            ],
            width: 12,
            worksWith: [FeatureType.ROAD,],
        },
    }

    // Returns a placeholder value for a parameter (Feature ID strategy types only).
    placeholderValue(parameter_key: string) {
        var options = this.parameters[parameter_key].select_classes as Array<{ text: string; value: any }>;
        var placeholderValue = options.find(option => option.value === (this.action as any)[parameter_key])?.text;  // Look up the 'text' component using the 'value'.
        return this.action?.strategy_type === StrategyType.FEATURE_ID ? placeholderValue : ''                       // Only use placeholders on Feature ID strategies.
    }

    // Event handler for when the Action Editor is closed.
    onCloseActionEditor() {
        this.$emit('action_editor_closed', false);
    }

    // Event handler for when a parameter value changes.
    onParameterChanged(updatedAction: any) {
        if (updatedAction.action_type !== ActionType.BUILD){
            updatedAction.action_type = updatedAction.parameters ? ActionType.ALTER : ActionType.CLOSE // Change the action type to a closure if there are no parameters.
        }
        this.action = updatedAction;
        this.$emit('action_updated', updatedAction);
    }
}

