




























































































































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { State, Action, Getter } from 'vuex-class';

import { bus } from '@/pages/transitweb/main'
import { DatasetState } from '@/store/datasets/types';

import { GChart } from 'vue-google-charts';
import { isNullOrUndefined } from 'util';

@Component({
    components: {
        GChart,
    }
})
export default class InfoPanelCharts extends Vue {

    @Prop() private unitType!: string;
    @Prop() private unitType2!: string;
    @Prop() private dataColumn!: string;
    @Prop() private chart_data!: any;
    @Prop() private isRoad!: boolean;
    @Prop() private statisticType!: string;
    @State('datasets') datasets!: DatasetState;
    ent_type: string = "orig_type";

    trailerItem(item: any) {
        return item[this.dataColumn];
    }

    sum(prev: number, next: number): number {
        return prev + next;
    }

    get top10AnnualTrailers(): number {
        var counts = 0;
        if (this.chart_data.link_movements) {
            counts = this.chart_data.link_movements.map(this.trailerItem).reduce(this.sum);
        }

        return counts;
    }

    expansionPanel: any = [null];

    get isCommoditiesExpanded(): boolean {
        return this.expansionPanel === 0;
    }

    get isIndustriesExpanded(): boolean {
        return this.expansionPanel === 1;
    }

    get isEnterpriseTypesExpanded(): boolean {
        return this.expansionPanel === 2;
    }

    get isMonthlyExpanded(): boolean {
        return this.expansionPanel === 3;
    }

    get isDirectionExpanded(): boolean {
        return this.expansionPanel === 4;
    }

    get isCostExpanded(): boolean {
        if (this.isRoad) {
            return this.expansionPanel === 5;
        } else {
            return this.expansionPanel === 4;
        }
    }

    get isProductivityExpanded(): boolean {
        if (this.isRoad) {
            return this.expansionPanel === 6;
        } else {
            return this.expansionPanel === 5;
        }
    }

    chartsLib: any = null;
    onChartReady(chart: any, google: any) {
        this.chartsLib = google
    }

    get commoditiesChartEvents(): any {
        var vm = this
        return {
            'select': function () {
                var chart = (vm.$refs.commoditiesChart as any).chartObject;
                var selection = chart.getSelection(); ///[0].row);
                if (selection.length) {
                    var selection_true = selection[0].row;
                    var e: any = [vm.chart_data.link_movements[selection_true]['commod_id']];
                    bus.$emit('append_name_filter', 'commodity', e);
                    //vm.$emit('infoPanelCharts', 'commodity', e);
                }
            }
        }
    }

    get industriesChartEvents(): any {
        var vm = this
        return {
            'select': function () {
                var chart = (vm.$refs.industriesChart as any).chartObject;
                var selection = chart.getSelection(); ///[0].row);
                if (selection.length) {
                    var selection_true = selection[0].row;
                    var e: any = [vm.chart_data.link_movements_industry[selection_true]['commod_l2']];
                    bus.$emit('append_name_filter', 'commod_l2', e);
                    //vm.$emit('infoPanelCharts', 'commod_l2', e);
                }
            }
        }
    }

    get monthlyChartEvents(): any {
        var vm = this
        return {
            'select': function () {
                var chart = (vm.$refs.monthlyChart as any).chartObject;
                var selection = chart.getSelection(); ///[0].row);
                if (selection.length) {
                    var selection_true = selection[0].row;
                    var e: any = [vm.chart_data.link_movements_temporal[selection_true]['month']].map(v => v.toLowerCase());
                    bus.$emit('append_name_filter', 'month', e);
                    //vm.$emit('infoPanelCharts', 'month', e);
                }
            }
        }
    }

    get chartDataByCommodity(): string[][] {
        var data = [];
        data.push(['Commodity', this.statisticType + ' ' + this.unitType]);
        if (this.chart_data.link_movements) {
            for (var i = 0; i < this.chart_data.link_movements.length; i++) {
                data.push([this.chart_data.link_movements[i]['commodity'],// + '  (' + this.numberWithCommas(this.chart_data.link_movements[i][this.dataColumn]) + ')',
                this.chart_data.link_movements[i][this.dataColumn],
                    //    this.chart_data.link_movements[i]['tonnes']
                ]);
            }

        }
        return data;

    }

    get chartOptionsByCommodity(): any {
        if (!this.chartsLib) return null
        let options: any
        return options = ({
            //return this.chartsLib.charts.Bar.convertOptions({
            chart: {
                //  title: 'Movements',
                // subtitle: ''
            },
            bars: 'horizontal', // Required for Material Bar Charts.
            series: {
                0: { axis: 'trailer_loads' }, // Bind series 0 to an axis named 'wagons'.
                1: { axis: 'tonnes' } // Bind series 1 to an axis named 'tonnes'.
            },
            axes: {
                x: {
                    trailer_loads: {
                        side: 'top',
                        //label: 'Annual Wagons'
                    },
                    tonnes: {
                        side: 'bottom',
                        //label: 'Annual Tonnes'
                    }
                },
                y: {
                    0: { side: 'bottom', label: "" }
                }
            },
            hAxis: {
                format: 'short',

            },
            height: 220,
            legend: { position: 'none' },
        })
    }

    get chartDataByIndustry(): string[][] {
        var data = [];
        data.push(['Industry', this.statisticType + ' ' + this.unitType]);
        if (this.chart_data.link_movements_industry) {
            for (var i = 0; i < this.chart_data.link_movements_industry.length; i++) {
                data.push([this.chart_data.link_movements_industry[i]['commod_l2'],
                this.chart_data.link_movements_industry[i][this.dataColumn],
                ]);
            }

        }
        return data;

    }

    get chartOptionsByIndustry(): any {
        if (!this.chartsLib) return null
        let options: any
        return options = ({
            //return this.chartsLib.charts.Bar.convertOptions({
            chart: {
                //  title: 'Movements',
                // subtitle: ''
            },
            bars: 'horizontal', // Required for Material Bar Charts.
            series: {
                0: { axis: 'trailer_loads' }, // Bind series 0 to an axis named 'wagons'.
                1: { axis: 'tonnes' } // Bind series 1 to an axis named 'tonnes'.
            },
            axes: {
                x: {
                    trailer_loads: {
                        side: 'top',
                        //label: 'Annual Wagons'
                    },
                    tonnes: {
                        side: 'bottom',
                        //label: 'Annual Tonnes'
                    }
                },
                y: {
                    0: { side: 'bottom', label: "" }
                }
            },
            hAxis: {
                format: 'short',

            },
            height: 220,
            legend: { position: 'none' },
        })
    }


    get chartDataByMonth(): string[][] {
        var data = [['Month', 'Monthly ' + this.unitType2]];
        if (this.chart_data.link_movements_temporal) {
            for (var i = 0; i < this.chart_data.link_movements_temporal.length; i++) {
                data.push([this.chart_data.link_movements_temporal[i]['month'],
                this.chart_data.link_movements_temporal[i]['trailer_loads'],
                ]);
            }

        }
        return data;
    }

    get chartOptionsByMonth(): any {
        if (!this.chartsLib) return null
        let options: any
        return options = ({
            //return this.chartsLib.charts.Bar.convertOptions({
            chart: {
                //  title: 'Movements',
                // subtitle: ''
            },
            hAxis: { format: 'decimal' },
            height: 200,
            legend: { position: 'none' },
            axes: {
                x: {
                    0: { side: 'bottom', label: "" }
                }
            }

        })
    }

    get chartDataByCost(): string[][] {
        var data = [['Cost type', 'Cost']]

        if (this.chart_data.link_movements_cost) {
            var costs = this.chart_data.link_movements_cost[0];

            for (var cost in costs) {
                // check if the property/key is defined in the object itself, not in parent
                if (costs.hasOwnProperty(cost)) {
                    data.push([cost, costs[cost]])
                }
            }
        }
        return data;

    }

    get chartOptionsByCost(): any {
        if (!this.chartsLib) return null
        var options = {
            //pieHole: 0.4,
            //hight: 400,
            width: 350,
            chartArea: { left: 10, width: '90%', height: '88%' },
            legend: { position: 'right', textStyle: { color: '#757575', fontName: 'Roboto', fontSize: 12 } }
        }
        return options;
    }

    get chartDataByProductivity(): string[][] {
        var data = [];
        data.push(['Truck type', this.statisticType + ' ' + 'Tonnes']);
        if (this.chart_data.link_movements_productivity) {
            for (var i = 0; i < this.chart_data.link_movements_productivity.length; i++) {
                data.push([this.chart_data.link_movements_productivity[i]['true_truck'],
                this.chart_data.link_movements_productivity[i]['tonnes'],
                ]);
            }
        }
        return data;

    }

    get chartOptionsByProductivity(): any {
        if (!this.chartsLib) return null
        let options: any
        return options = ({
            bars: 'horizontal', 
            series: {
                0: { axis: 'tonnes' },
            },
            axes: {
                x: {
                    tonnes: {
                        side: 'top',
                    },
                },
                y: {
                    0: { side: 'bottom', label: "" }
                }
            },
            hAxis: {
                format: 'short',
            },
            height: 220,
            legend: { position: 'none' },
        })
    }

    get chartDataByDirection(): string[][] {
        var data = [['Commodity', 'North', 'South']];
        if (this.chart_data.link_movements_direction) {
            var directions = Object.keys(this.chart_data.link_movements_direction)
            if (directions[1]) {
                data = [['Commodity', directions[0], directions[1]]];
            } else {
                data = [['Commodity', directions[0]]];

            }
            var dirAList = this.chart_data.link_movements_direction[directions[0]]
            var dirBList = this.chart_data.link_movements_direction[directions[1]]
            for (var commodity in dirAList) {
                if (directions[1]) {
                    var dirB = 0
                    if (this.chart_data.link_movements_direction[directions[1]]) {
                        if (this.chart_data.link_movements_direction[directions[1]][commodity]) {
                            dirB = Number(this.chart_data.link_movements_direction[directions[1]][commodity])
                        }
                    }
                    data.push([commodity,
                        dirAList[commodity],
                        dirB,
                    ]);
                } else {
                    data.push([commodity,
                        dirAList[commodity],
                    ]);
                }
            }
            for (var commodity in dirBList) {
                if (isNullOrUndefined(this.chart_data.link_movements_direction[directions[0]][commodity])) {
                    data.push([commodity,
                        dirBList[commodity],
                        0,
                    ]);
                }
            }

        }

        return data;

    }

    get chartOptionsByDirection(): any {
        if (!this.chartsLib) return null
        let options: any
        return options = ({
            //return this.chartsLib.charts.Bar.convertOptions({
            chart: {
                //  title: 'Movements',
                // subtitle: ''
            },
            bars: 'horizontal', // Required for Material Bar Charts.
            //series: {
            //    0: { axis: 'trailer_loads' }, // Bind series 0 to an axis named 'wagons'.
            //    1: { axis: 'tonnes' } // Bind series 1 to an axis named 'tonnes'.
            //},
            axes: {
                x: {

                    0: {
                        side: 'bottom',
                        label: this.statisticType + ' ' + 'Trailers'
                    }
                    //,
                    //tonnes: {
                    //    side: 'top',
                    //    //label: 'Annual Trailers'
                    //}
                },
                y: {
                    0: { side: 'bottom', label: "" }
                }
            },
            hAxis: {
                format: 'short',
                //gridlines: {
                //    count: 10
                //},
                //minorGridlines: {
                //    count: 10
                //}

            },
            height: 220,
            //isStacked: true,
            //legend: { position: 'none' },
            bar: { groupWidth: "100%" }
        })
    }

    get enterpriseDestChartEvents(): any {
        var vm = this
        return {
            'select': function () {
                var chart = (vm.$refs.dest_type as any).chartObject;
                var selection = chart.getSelection(); ///[0].row);
                if (selection.length) {
                    var selection_true = selection[0].row;
                    var e: any = [vm.chart_data.link_movements_desttype[selection_true]['dest_type']];
                    bus.$emit('append_name_filter', 'destenterprise', e);
                    //vm.$emit('infoPanelCharts', 'destenterprise', e);
                }
            }
        }
    }

    get enterpriseOrigChartEvents(): any {
        var vm = this
        return {
            'select': function () {
                var chart = (vm.$refs.orig_type as any).chartObject;
                var selection = chart.getSelection(); ///[0].row);
                if (selection.length) {
                    var selection_true = selection[0].row;
                    var e: any = [vm.chart_data.link_movements_origtype[selection_true]['orig_type']];
                    bus.$emit('append_name_filter', 'origenterprise', e);
                    //vm.$emit('infoPanelCharts', 'origenterprise', e);
                }
            }
        }
    }

    chartDataByEnterpriseType(type: string, datasource: any): string[][] {
        var data = [];
        data.push(['Enterprise Type', this.statisticType + ' ' + this.unitType]);
        if (datasource) {
            for (var i = 0; i < datasource.length; i++) {
                data.push([datasource[i][type],// + '  (' + this.numberWithCommas(this.chart_data.link_movements[i][this.dataColumn]) + ')',
                datasource[i][this.dataColumn],
                    //    this.chart_data.link_movements[i]['tonnes']
                ]);
            }

        }

        return data;

    }

    get chartOptionsByEnterpriseType(): any {
        if (!this.chartsLib) return null
        let options: any
        return options = ({
            //return this.chartsLib.charts.Bar.convertOptions({
            chart: {
                //  title: 'Movements',
                // subtitle: ''
            },
            bars: 'horizontal', // Required for Material Bar Charts.
            series: {
                0: { axis: 'trailer_loads' }, // Bind series 0 to an axis named 'wagons'.
                1: { axis: 'tonnes' } // Bind series 1 to an axis named 'tonnes'.
            },
            axes: {
                x: {
                    trailer_loads: {
                        side: 'top',
                        //label: 'Annual Wagons'
                    },
                    tonnes: {
                        side: 'bottom',
                        //label: 'Annual Tonnes'
                    }
                },
                y: {
                    0: { side: 'bottom', label: "" }
                }
            },
            hAxis: {
                format: 'short',

            },
            height: 120,
            legend: { position: 'none' },
        })
    }
}


