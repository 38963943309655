import 'es6-promise/auto'

import Vue from 'vue';
import App from './App.vue';

import router from "@/router";

import store from '@/store';

//import VueSession from 'vue-session'
//Vue.use(VueSession)

//import { Plotly } from 'vue-plotly'
//Vue.use(Plotly)

Vue.config.productionTip = true;

import axios from 'axios'
import VueAxios from 'vue-axios'

Vue.use(VueAxios, axios);

import VueGtm from 'vue-gtm';
Vue.use(VueGtm, {
    id: 'GTM-KNFTPTN',// or ['GTM-xxxxxxx', 'GTM-xxxxxxx'], // Your GTM single container ID or array of container ids ['GTM-xxxxxxx', 'GTM-yyyyyyy']
    //queryParams: { // Add url query string when load gtm.js with GTM ID (optional)
    //    gtm_auth: 'AB7cDEf3GHIjkl-MnOP8qr',
    //    gtm_preview: 'env-4',
    //    gtm_cookies_win: 'x'
    //},
    enabled: true, // defaults to true. Plugin can be disabled by setting this to false for Ex: enabled: !!GDPR_Cookie (optional)
    debug: true, // Whether or not display console logs debugs (optional)
    loadScript: true, // Whether or not to load the GTM Script (Helpful if you are including GTM manually, but need the dataLayer functionality in your components) (optional)
    vueRouter: router, // Pass the router instance to automatically sync with router (optional)
    //ignoredViews: ['homepage'] // If router, you can exclude some routes name (case insensitive) (optional)
});

//axios.defaults.headers.common['X-Frame-Options'] = 'DENY';
//axios.defaults.validateStatus = (status) => {
//    return status >= 200 && status <= 300; // default
//}

//import VueMapbox from 'vue-mapbox'
//import Mapbox from 'mapbox-gl'
//Vue.use(VueMapbox, { mapboxgl: Mapbox })


import Vuetify from 'vuetify'
Vue.use(Vuetify);
const opts = {
    theme: {
        options: { customProperties: true },
        themes: {
            light: {
                //primary: "#00698f", // datahub #135e70 freight #00698f :
                //primary: "white", // datahub #135e70 freight #00698f :
                //secondary: "#61daff", //
                accent: "#61daff", // datahub: #418fde freight #61daff
                nav_bar: "#013e75",
                footer_toolbox: "#013e75",
                footer_navbar: "#1976d2",
                chip_bg: "#FFFFFF",
                //chip_text: "white",
                //filter_bg: "#00698f",
                lines: "#1976d2",
            } // #003b4c

        }

    }
}
import 'vuetify/dist/vuetify.min.css' // Ensure you are using css-loader
import 'material-design-icons-iconfont/dist/material-design-icons.css'

export const bus = new Vue(); // Create an event bus


// https://github.com/Drackokacka/vue-web-speech
// Use a custom vue-web-speech component for now, as the node package has safari issues in it's current form.
//import VueWebSpeech from 'vue-web-speech';
//Vue.use(VueWebSpeech);

var vm = new Vue({
    vuetify: new Vuetify(opts),
    router,
    store,
    render: h => h(App)
}).$mount('#app');
