




























































































import { Component, Prop, Vue } from 'vue-property-decorator';
import { State, Action, Getter } from 'vuex-class';

import { AuthState, AuthStatus } from '@/store/auth/types';

import { mixins } from 'vue-class-component';
import Validation from './validation-mixin'

@Component
export default class SignIn extends mixins(Validation) {

    @State('auth') auth!: AuthState;

    //@Action('auth/login') login: any;    // Django login process
    @Action('auth/loginMFA') login: any;   // Django Trench login process
    @Getter('auth/isMfaRequired') isMfaRequired?: boolean;
    @Getter('auth/isMfaActivated') isMfaActivated?: boolean;

    showPassword = false;
    credentials: any = {};

    //valid: boolean = true; // Override mixin value. Not needed?

    error_message: string = ''
    get error() {
        return this.error_message !== ''
    }


    get loading(): boolean {
        return this.auth.status === AuthStatus.Loading;
    }


    onLogin() {

        var vm = this;
        this.login(this.credentials)
            .then(function (response: any) {

                // Might still have MFA enabled on Django Trench so check it (isMfaActivated).
                // i.e.If there is a database entry(in the admin interface) for this account in the Trench MFA Methods model, it will use it!
                if (vm.isMfaRequired || vm.isMfaActivated) {
                    vm.$router.push({ name: 'MFA', query: vm.$route.query, params: { credentials: vm.credentials } })
                } else {
                    vm.$router.push("/")
                }

            })
            .catch(function (error: any) {
                console.log(error)
                vm.error_message = error;
            });

    }


    aboutTransit() {
        (window as any).open("https://www.csiro.au/TRANSIT");
    }


}

