



























































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { State, Action, Getter } from 'vuex-class';

import { DensityType, DatasetState } from '@/store/datasets/types';

@Component({
    components: {
    }
})
export default class MovementsReport extends Vue {

    @Prop() private reportConfig!: any;
    @Prop() private reportData!: any;
    @Prop() private isDownloadDisabled!: boolean;
    @Prop() private downloading_report!: boolean;

    @Prop() private isStandAlone!: boolean;

    @State('datasets') datasets!: DatasetState;

    @Action('map/setFlyTo') setFlyTo: any;

    numberWithCommas(x: any): string {
        return Number(x).toLocaleString()
    }

    DensityType: any = DensityType;

    get densityType(): DensityType {
        return this.datasets.type;
    }

}

