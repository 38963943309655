
import { Component, Prop, Vue } from 'vue-property-decorator';

import { mixins } from 'vue-class-component';
import EndpointData from './data-mixin';

@Component({
    components: {
    }
})
export default class QueryEndpointData extends mixins(EndpointData) {

    // Supply chain leg config prop.
    @Prop() protected config!: any;

    // Display props.
    @Prop() protected commodity!: string;
    @Prop() protected metric!: any;
    @Prop() protected spatial_type!: any;
    @Prop() protected border_crossings!: number | null;
    @Prop() protected spatial_selection!: string;
    @Prop() protected supply_chain_leg!: string;


    get regions() {
        // Prepend the direction into the region selected.
        return this.config.direction + '_' + this.spatial_type.value;
    }


    get updateRequired() {
        // A getter for inheritortors to watch if the data requires updating.
        return `${this.commodity} | ${this.border_crossings} | ${this.spatial_selection} | ${this.supply_chain_leg} | ${this.metric.value} | ${this.spatial_type.value}`;
    }


    getQueryParams() {
        // Build the endpoint url based upon SCE filter values.
        var queryParams: any = {};

        queryParams["dataset"] = this.dataset;
        queryParams["commodity"] = this.commodity;
        queryParams["metric"] = this.metric.value;
        if (this.border_crossings) {
            queryParams["border_crossings"] = this.border_crossings;
        }
        queryParams["regions"] = this.regions;

        // Include trips within the region (i.e. intrastate).
        // Setting to false will only show border crossings.
        // This is only a temporary parameter for now while using the SCBD endpoint.
        // Ultimately the border_crossings parameter will get used for the SCE.
        queryParams["local_trips"] = "true";

        if (this.spatial_selection) {
            queryParams[this.config.direction + "_region"] = this.spatial_selection;
            queryParams[this.config.direction + "_region_category"] = this.regions;
            queryParams["supply_chain_leg"] = this.supply_chain_leg;
        }

        return queryParams;
    }
}
