// Must be lowercase to be able to add as an attribute to Cloud Run Annotation.
export enum TaskType {
    Scenario = "scenario",
    Scenario_Test = "scenario_test"
}
export enum TaskStatus {
    Submitted = "Submitted",
    Complete = "Complete",
    Running = "Running",
    Failed = "Failed",
    Cancelled = "Cancelled"
}

